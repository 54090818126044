import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {compose, withProps} from "recompose";

import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  Icon,
  IconButton,
  Radio,
  Typography,
  Box,
  Tooltip,
  Popover, Stack, Switch
} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import FormControlLabel from '@mui/material/FormControlLabel';

import ComponentTypes from "../../../componentTypes";
import {dataExplorationChartState} from "../../../../state/common/dataExplorationChart/dataExplorationChartSelectors";
import * as dataExplorationChartActions from "../../../../state/common/dataExplorationChart/dataExplorationChartActions";
import * as sensorSelectorActions from '../../../../state/common/sensorSelector/sensorSelectorActions';
import * as appErrorActions from '../../../../state/app/actions/appErrorActions';
import Chart from "../../../controls/charts/interactive/chart";
import LegendTable from "./legendTable";
import AxisIndicator from "./axisIndicator";
import { mdtPalette } from "../../styles/mdtPalette";
import SensorSelector from "./sensorSelector";
import Progress from "../../../controls/progress";
import getTypographyStyles from "../../styles/typographyStyles";
import Transition from "../../../controls/dialogSlideTransition";
import {MdtYAxisPosition} from "../../../../state/common/dataExplorationChart/mdtYAxisPosition";
import CreateSensorGroupDialog from "./createEditSensorGroupDialog";
import ExportCsv from '../../../controls/exportCsv/exportCsv';
import { appState as applicationState } from '../../../../state/app/appSelectors';
import SettingsIcon from '@mui/icons-material/Settings';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import takeScreenShot from '../../../../helpers/screenshotHelper'

import MdtColorPicker from '../../colorPicker/mdtColorPicker';

const typographyStyles = getTypographyStyles();
const topLevelContainerBaseStyle = {
  display: 'flex',
  height: '100%',
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 1,
  paddingRight: 1,
  flexFlow: 'column',
  backgroundColor: 'background.paper',
  borderRadius: '4px',
}
const topLevelLegendMenuColumnContainerBaseStyle = {
  width: '33.33%',
}

const styles = (props) => {

  const rotated = _.isNil(props) || _.isNil(props.isRotated) ? false : props.isRotated;

  return {
    ...typographyStyles,
    topLevelContainer: {
      ...topLevelContainerBaseStyle
    },
    topLevelLegendContainer: {
      ...topLevelContainerBaseStyle,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    topLevelLegendMenuContainer: {
      ...topLevelContainerBaseStyle,
      backgroundColor: mdtPalette().materialUI.palette.background.default,
      flexFlow: null,
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    topLevelLegendMenuButtonsContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      width: '100%',
    },
    topLevelLegendMenuCenterColumnContainer: {
      ...topLevelLegendMenuColumnContainerBaseStyle,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center'
    },
    topLevelLegendMenuLeftColumnContainer: {
      ...topLevelLegendMenuColumnContainerBaseStyle,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start'
    },
    topLevelLegendMenuRightColumnContainer: {
      ...topLevelLegendMenuColumnContainerBaseStyle,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end'
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 1,
      paddingLeft: 1,
      paddingRight: 1,
    },
    cardHeaderLabel: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
      borderBottomColor: 'primary.main',
    },
    tabBarLabel: {
      width: '100%',
      height: '48px',
    },
    sciChart: {},
    paper: {
      position: 'absolute',
      padding: 4,
      outline: 'none',
    },
    noDataHolder: {
      height: '500px',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noDataLabel: {
      style: typographyStyles.noDataLabel
    },
    buttonIcon: {
      marginLeft: 1,
    },
    dialogPaper: {
      minHeight: '840px',
      maxHeight: '840px',
    },
    colorPickerPopover: {
      position: 'relative',
      top: '-24px', // == (-3 x theme.spacing()) == (-3 x 8px)
    },
    includeLegendFormControl: {
      marginLeft: '0px'
    },
    includeLegendLabel: {
      fontSize: '0.75rem'
    },
    axisRadio: {
      padding: '0px', 
      paddingLeft: '2px', 
      paddingRight: '2px'
    },
    chartActionsLegendContainer: {
      height: rotated ? '100%' : '50%',
    },
    logViewScreenShotAreaContainer: {
      width: rotated ? '69%' : '100%',
      flex: "auto",
      flexDirection: "row",
      display: "flex",
    },
    logViewSpacerContainer: {
      width: '25%', 
      minWidth: '25%', 
      display: 'flex', 
      flexFlow: 'column nowrap' 
    },
    logViewTooltipDiv: {
      display: 'flex', 
      flexFlow: 'column nowrap', 
      justifyContent: 'center', 
      height: '80.6%',
      width: '385px',
    },
    logViewTooltipDivBottomSpacer: {
      display: 'flex', 
      flexFlow: 'column nowrap', 
      justifyContent: 'center'
    },
    chartSettingsButton: {
      display:'flex', 
      justifyContent: 'flex-end'
    }
  }
};

/**
 * Exposes data exploration as a reusable component. Accepts a chart definition template as input.
 */
class DataExplorationChart extends Component {

  constructor(props) {
    super(props);
    this.renderTableCell = this.renderTableCell.bind(this);
    this.sensorSelectorCallback = this.sensorSelectorCallback.bind(this);
  }

  componentDidMount() {
    // we initialize the definition with the template sensors and config only
    // if there are no sensors defined; this allows our definition to be
    // maintained when navigating away from the view
    let sensorCount = this.props.definition.primary.sensors.length + this.props.definition.secondary.sensors.length;
    this.props.onTemplateChanged(this.props.template, sensorCount === 0);
  }

  componentDidUpdate(prevProps) {

    const manualRefresh = !_.isEqual(prevProps.manualRefreshTime, this.props.manualRefreshTime);

    // update the definition upon template change
    if (!_.isEqual(prevProps.template, this.props.template) || manualRefresh) {
      this.props.onTemplateChanged(this.props.template, false);
    }

    // query sensor data upon definition truck/time/duration/sensor change, or force refresh on custom selected timeframe
    const startTime = this.props.definition.primary.timeRange.startTime;
    const defaultTruck = this.props.definition.primary.defaultTruck;
    const duration = this.props.definition.primary.timeRange.duration;
    const sensors = this.props.definition.primary.sensors;
    const truckChange = !_.isEqual(prevProps.definition.primary.defaultTruck, defaultTruck) && !_.isNil(defaultTruck) && (defaultTruck.pid !== 0);
    const startChange = !_.isEqual(prevProps.definition.primary.timeRange.startTime, startTime);
    const durationChange = !_.isEqual(prevProps.definition.primary.timeRange.duration, duration);
    const sensorsChange = !_.isEqual(prevProps.definition.primary.sensors, sensors);
    const forceReload = this.props.selectedTimeFrame === 'Custom' && manualRefresh;

    if (truckChange || sensorsChange){
      //If there're changes to either trucks or selected sensors, reset the sensor data
      this.props.resetQuerySensorData();
    }
    if ((truckChange || startChange || durationChange || sensorsChange || forceReload) && !_.isNil(defaultTruck) &&  (defaultTruck.pid !== 0) && !_.isEmpty(sensors))  {

      // If we already have data and we may have panned around a bit, the startTime and (startTime + (60 * duration)) = endTime
      // might not be valid now for any new sensors we add
      let earliestTimeAlreadyQueried = _.head(this.props.data.primary.xValues);
      let latestTimeAlreadyQueried = _.last(this.props.data.primary.xValues);
      let newStartTime = (!_.isNil(earliestTimeAlreadyQueried) && earliestTimeAlreadyQueried !== startTime) ? earliestTimeAlreadyQueried : startTime;
      let newEndTime = (!_.isNil(latestTimeAlreadyQueried) && latestTimeAlreadyQueried !== startTime + (60 * duration)) ? latestTimeAlreadyQueried : startTime + (60 * duration);

      // Chunk and query the data for the time range
      this.props.queryByChunks(defaultTruck, newStartTime, newEndTime, sensors);
    } 
    // If we cleared out the sensors list, then treat it as a template change to act as a 'reset' action
    else if (sensorsChange && _.isEmpty(sensors)) {
      this.props.onTemplateChanged(this.props.template, false);
    }
    
  }

  generateTableColumns() {
    let columns = [];
    const spacer = this.props.isRotated ? 37.5 : 75; // ugh, columns not centered, use the largest value that does not draw the box outside the parent
    columns.push({type: 'data', width: spacer / 2, label: '', displayProperty: '', sortProperty: ''});
    columns.push({type: 'custom', width: this.props.isRotated ? 50 : 100, label: 'Visible', displayProperty: 'visible', sortProperty: 'visible'});

    columns.push({type: 'custom', width: this.props.isRotated ? 250 : 500, label: 'Series', displayProperty: 'series', sortProperty: 'series'});

    columns.push({type: 'data', width: spacer, label: '', displayProperty: '', sortProperty: ''});

    columns.push({type: 'data', width: this.props.isRotated ? 50 : 100, label: 'Samples', displayProperty: 'count', sortProperty: 'count'});
    columns.push({type: 'data', width: this.props.isRotated ? 50 : 100, label: 'Min', displayProperty: 'min', sortProperty: 'min'});
    columns.push({type: 'data', width: this.props.isRotated ? 50 : 100, label: 'Mean', displayProperty: 'mean', sortProperty: 'mean'});
    columns.push({type: 'data', width: this.props.isRotated ? 50 : 100, label: 'Max', displayProperty: 'max', sortProperty: 'max'});

    columns.push({type: 'data', width: spacer, label: '', displayProperty: '', sortProperty: ''});

    columns.push({type: 'custom', width: this.props.isRotated ? 50 : 100, label: 'Color', displayProperty: 'color', sortProperty: 'color'});
    columns.push({type: 'custom', width: this.props.isRotated ? 105 : 210, label: 'Axis Position', displayProperty: 'axis', sortProperty: 'axis'});

    columns.push({type: 'data', width: spacer / 2, label: '', displayProperty: '', sortProperty: ''});
    return columns;
  }

  renderTableCell(tableContext, column, item) {
    const showColorPicker = !_.isNil(this.props.colorPickerState) &&
      this.props.colorPickerState.xAxisId === item.xAxisId &&
      this.props.colorPickerState.refIndex === item.refIndex;

    if (column.displayProperty === 'series') {
      return (
        <Tooltip followCursor title={item.sensorName}>
          <Box sx={{width: column.width, maxWidth: column.width, whiteSpace: 'nowrap'}} 
              align={"left"}>
            <Typography variant={"subtitle1"}
                        sx={{color: item.color, textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.sensorName}&nbsp;&nbsp;&nbsp;&nbsp;({item.sensorUom})</Typography>
          </Box>
        </Tooltip>
      );
    }

    if (column.displayProperty === 'color') {
      return (
        <Box sx={{width: column.width, display: 'flex', justifyContent: 'center'}} align={"center"}>
          <MdtColorPicker
            showColorPicker={showColorPicker}
            id={item.xAxisId}
            color={item.color}
            origColor={this.props.colorPickerState ? this.props.colorPickerState.origColor : null}
            onSetColorPickerState={(xAxisId, origColor) => this.props.onSetColorPickerState(xAxisId, item.refIndex, origColor)}
            onConfigChangedColor={(xAxisId, color) => this.props.onConfigChangedColor(xAxisId, item.refIndex, color)}
            popoverStyles={styles(this.props).colorPickerPopover}
          />
        </Box>
      );
    }

    if (column.displayProperty === 'axis') {
      return (
        <Box sx={{width: column.width, display: "flex", whiteSpace: 'nowrap', justifyContent: 'center'}}>
          <Radio
            sx={styles(this.props).axisRadio}
            checked={item.yAxisId === MdtYAxisPosition.LeftOuter}
            color={"default"}
            onChange={() => this.props.onConfigChangedYAxisId(item.xAxisId, item.refIndex, MdtYAxisPosition.LeftOuter)}
            value={MdtYAxisPosition.LeftOuter}
            name="axis"
          />
          <Radio
            sx={styles(this.props).axisRadio}
            checked={item.yAxisId === MdtYAxisPosition.LeftInner}
            color={"default"}
            onChange={() => this.props.onConfigChangedYAxisId(item.xAxisId, item.refIndex, MdtYAxisPosition.LeftInner)}
            value={MdtYAxisPosition.LeftInner}
            name="axis"
          />
          <Box sx={{width: '20px'}} />
          <Radio
            sx={styles(this.props).axisRadio}
            checked={item.yAxisId === MdtYAxisPosition.RightInner}
            color={"default"}
            onChange={() => this.props.onConfigChangedYAxisId(item.xAxisId, item.refIndex, MdtYAxisPosition.RightInner)}
            value={MdtYAxisPosition.RightInner}
            name="axis"
          />
          <Radio
            sx={styles(this.props).axisRadio}
            checked={item.yAxisId === MdtYAxisPosition.RightOuter}
            color={"default"}
            onChange={() => this.props.onConfigChangedYAxisId(item.xAxisId, item.refIndex, MdtYAxisPosition.RightOuter)}
            value={MdtYAxisPosition.RightOuter}
            name="axis"
          />
        </Box>
      );
    }

    if (column.displayProperty === 'visible') {
      return (
        <Box sx={{width: column.width, display: "flex", whiteSpace: 'nowrap', justifyContent: 'center'}}>
          <Checkbox
            color={"default"}
            checked={item.isVisible === true}
            onChange={() => this.props.onConfigChangedVisible(item.xAxisId, item.refIndex, !item.isVisible)}
            icon={<CheckBoxOutlineBlank fontSize="small"/>}
            checkedIcon={<CheckBox fontSize="small"/>}
          />
        </Box>
      );
    }

    return (<div></div>);
  }

  sensorSelectorCallback(sensors) {
    this.props.setSelectedSensors('primary', sensors);
  }

  render() {
    const noSensorsSelected = _.isEmpty(this.props.primaryDef.sensors);
    const noSensorsVisible = !_.some(this.props.definition.primary.config, { 'isVisible': true});

    const noData = 
      !_.isEmpty(this.props.primaryDef.sensors) &&
      (
      _.isNil(this.props.data) || _.isNil(this.props.data.primary) ||
      _.isNil(this.props.data.primary.yValues) || _.isEmpty(this.props.data.primary.yValues) ||
      _.every(this.props.data.primary.yValues, data => _.isEmpty(data))
      );

    let columns = this.generateTableColumns();

    let sensorSelectorStateDef = getSensorSelectorStateDef(this.props);
    const onTakeScreenShot = () => takeScreenShot(null, this.props.onTakeScreenShotStart, this.props.onTakeScreenShotSuccess);
    return (
      <Box sx={styles(this.props).logViewScreenShotAreaContainer}>
        <Grid container direction={'column'} justifyContent={'flex-start'} alignItems={'stretch'}>
          <Box sx={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
            <Box sx={{display: 'flex', width: '100%', flexFlow: 'row nowrap'}}>
              <Box sx={styles(this.props).chartActionsLegendContainer}>
                <Grid item sx={{...styles(this.props).topLevelContainer, height: 'auto', width: '100%', position: 'relative'}}>
                  <Box id={"plot-config-buttons"} sx={{position: 'absolute', top: 0, right: 0, zIndex: 1}} screenshotignore="true">
                    <Box sx={styles(this.props).chartSettingsButton}>
                      <Tooltip disableInteractive title={"Chart Settings"}>
                        {/* Tooltip does not like having a disabled button with no events to react to.
                            Wrapping it in a div (as the warning suggests) makes it happy again */}
                        <Box>
                          <IconButton
                              onClick={(event) => this.props.onClickChartSettingsPopup(event.currentTarget)}
                              size="small" disabled={noSensorsSelected || noSensorsVisible } >
                            <SettingsIcon />
                          </IconButton>
                        </Box>
                      </Tooltip>
                      <Popover
                          open={this.props.openChartSettingsPopup}
                          anchorEl={this.props.chartSettingButtonAnchor}
                          onClose={()=>this.props.onClickChartSettingsPopup(this.props.chartSettingButtonAnchor)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                      >
                        <Stack direction="column" sx={{padding: 1}}>
                          <FormControlLabel
                            control={<Switch
                              checked={this.props.closeNaNGap}
                              onChange={() => this.props.onToggleCloseNaNGap()} />}
                            label='Close Gaps'/>
                          <FormControlLabel
                            control={<Switch
                              checked={this.props.isRotated}
                              onChange={(event) => {this.props.onRotateAxes(event.target.checked)}} />}
                            label='Log View'/>
                        </Stack>
                      </Popover>
                    </Box>
                    <Stack direction="row" sx={{justifyContent: "right"}}>
                      <Box sx={styles(this.props).chartSettingsButton}>
                        <Tooltip disableInteractive title={"Take Screenshot"}><Box>
                          <IconButton
                              onClick={() => {
                                if (this.props.isDelayedScreenShot) {
                                  setTimeout(() => {onTakeScreenShot()}, 2000);
                                } else { onTakeScreenShot(); }
                              }}
                              size="small" disabled={noSensorsSelected || noSensorsVisible } >
                            <ScreenshotIcon />
                          </IconButton>
                        </Box></Tooltip>
                      </Box>
                      <Box sx={{...styles(this.props).chartSettingsButton, width: "25%"}}>
                        <Tooltip disableInteractive title={"Screenshot Settings"}><Box>
                          <IconButton
                              onClick={(event) => this.props.onClickScreenShotSettingsPopup(event.currentTarget)}
                              size="small" disabled={noSensorsSelected || noSensorsVisible } >
                            <ArrowDropDownIcon />
                          </IconButton>
                        </Box></Tooltip>
                        <Popover
                            open={this.props.openScreenShotSettingsPopup}
                            anchorEl={this.props.screenShotButtonAnchor}
                            onClose={()=>this.props.onClickScreenShotSettingsPopup(this.props.screenShotButtonAnchor)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                        >
                          <FormControlLabel
                            sx={{marginLeft: '0px'}}
                            control={<Switch
                              checked={this.props.isDelayedScreenShot}
                              onChange={() => this.props.onToggleDelayedScreenShot()} />}
                            label='Delay 2 Seconds'/>
                        </Popover>
                      </Box>
                    </Stack>
                  </Box>
                  <Grid container direction={this.props.isRotated ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item>
                      <AxisIndicator yAxisId={MdtYAxisPosition.LeftOuter} definition={this.props.definition} isRotated={this.props.isRotated}/>
                    </Grid>
                    <Grid item>
                      <AxisIndicator yAxisId={MdtYAxisPosition.LeftInner} definition={this.props.definition} isRotated={this.props.isRotated}/>
                    </Grid>
                    <Grid item sx={{width: this.props.isRotated ? '100%' : '90%', flexGrow: 1}}>
                      {
                        noSensorsSelected &&
                        <Box sx={styles(this.props).noDataHolder}>
                          <Typography variant={'subtitle1'} sx={styles.noDataLabel}>Please Select Sensors to Chart</Typography>
                        </Box>
                      }
                      {!noData && 
                      !noSensorsSelected &&
                      <Chart
                          className={styles(this.props).sciChart}
                          style={{height: this.props.isRotated ? 900 : 500}} // TODO: still need to hardcode the height; it's not passed in correctly
                          primaryDefinition={this.props.definition.primary}
                          primaryData={this.props.data.primary}
                          onPrimaryXAxisChange={(...args) => {
                            this.props.onAxisChange(...args, this.props.onAxisUpdateCallback);
                          }}
                          secondaryDefinition={this.props.definition.secondary}
                          secondaryData={this.props.data.secondary}
                          onSecondaryXAxisChange={(...args) => {
                            this.props.onAxisChange(...args, this.props.onAxisUpdateCallback);
                          }}
                          zoomLevel={this.props.definition.primary.timeRange.duration}
                          rotate={this.props.isRotated}
                          closeNaNGap={this.props.closeNaNGap}
                          chartModifiers={this.props.chartModifiers}
                      />
                      }
                      {noData && 
                      !noSensorsSelected &&
                      <Box sx={styles(this.props).noDataHolder}>
                        <Typography variant={'subtitle1'} sx={styles.noDataLabel}>No Data</Typography>
                      </Box>
                      }
                    </Grid>
                    <Grid item>
                      <AxisIndicator yAxisId={MdtYAxisPosition.RightInner} definition={this.props.definition} isRotated={this.props.isRotated}/>
                    </Grid>
                    <Grid item>
                      <AxisIndicator yAxisId={MdtYAxisPosition.RightOuter} definition={this.props.definition} isRotated={this.props.isRotated}/>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{...styles(this.props).topLevelLegendMenuContainer, width: '100%', height: 'auto'}}  screenshotignore="true">
                  <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={styles(this.props).tabBarLabel}>
                    <Grid item sx={styles(this.props).topLevelLegendMenuLeftColumnContainer}>
                        <Button variant='text'
                                color="primary"
                                sx={styles(this.props).button}
                                size={"small"}
                                onClick={() => {
                                  this.props.downloadData()
                                }}
                                disabled={_.isEmpty(this.props.data.primary.yValues) || this.props.data.primary.yValues.length === 0 ||
                                          noSensorsVisible }
                                >
                          Download Visible Data
                          <Icon sx={styles(this.props).buttonIcon}>get_app</Icon>
                        </Button>
                    </Grid>
                    <Grid item sx={styles(this.props).topLevelLegendMenuCenterColumnContainer}>
                      <Button variant='text'
                              color="primary"
                              sx={styles(this.props).button}
                              size={"small"}
                              onClick={() => {
                                this.props.openSensorSelector()
                              }}
                              disabled={_.isNil(this.props.definition.primary.defaultTruck) || this.props.definition.primary.defaultTruck.pid === 0}
                              >
                        Edit Sensors
                        <Icon sx={styles(this.props).buttonIcon}>edit_note</Icon>
                      </Button>
                    </Grid>
                    <Grid item sx={styles(this.props).topLevelLegendMenuRightColumnContainer}>
                      {this.props.isUserAdmin &&
                      <Button variant='text'
                              color="primary"
                              sx={styles(this.props).button}
                              size={"small"}
                              onClick={() => {
                                this.props.openCreateSensorGroup()
                              }}
                              disabled={this.props.definition.primary.sensors.length === 0}>
                        Save As Group
                        <Icon sx={styles(this.props).buttonIcon}>post_add</Icon>
                      </Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{...styles(this.props).topLevelLegendContainer, height: 'auto'}}>
                  <LegendTable
                    stateDef={this.props.stateDef}
                    columns={columns}
                    items={this.props.legendItems}
                    disable={this.props.queryRunning === true}
                    renderTableCell={this.renderTableCell}
                  />
                </Grid>
              </Box>
              {
                this.props.isRotated &&
                <Box sx={styles(this.props).logViewSpacerContainer}> 
                  <Box id="tooltip-div" sx={styles(this.props).logViewTooltipDiv}/>
                  <Box sx={styles(this.props).logViewTooltipDivBottomSpacer}/>
                </Box>
              } 
            </Box>
          </Box>
        </Grid>
        <Dialog maxWidth={'lg'}
                fullWidth={true}
                open={this.props.shouldOpenSensorSelector}
                onClose={this.props.closeSensorSelector}
                TransitionComponent={Transition}
                PaperProps={{paper: styles(this.props).dialogPaper}}
        >
          <SensorSelector
            parentCallback={this.sensorSelectorCallback}
            initalSelectedSensors={this.props.definition.primary.sensors}
            trucks={_.isNil(this.props.definition.primary.defaultTruck) ? [] : [this.props.definition.primary.defaultTruck.pid]}
            startTime={this.props.definition.primary.timeRange.startTime}
            endTime={this.props.definition.primary.timeRange.startTime + (60 * this.props.definition.primary.timeRange.duration)}
            onClose={this.props.closeSensorSelector}
            stateKey={sensorSelectorStateDef.key}
            namespace={'DXP'}
            unitType={this.props.unitType}
            axisDefinition={this.props.definition.primary}
          />
        </Dialog>
        <CreateSensorGroupDialog stateDef={sensorSelectorStateDef} unitType={this.props.unitType} axisDefinition={this.props.definition.primary} namespace={'DXP'} />
        <ExportCsv triggerExportCsv={this.props.triggerExportCsv} exportCsvUrl={this.props.exportCsvUrl}/>
        <Box screenshotignore="true">
          <Progress open={this.props.queryRunning}/>
        </Box>
      </Box>
    );
  }
}

function getSensorSelectorStateDef(props) {
  let sensorSelectorKey = ComponentTypes.SENSOR_SELECTOR + '_' + props.stateKey;
  let sensorSelectorStateDef = 
  {
    key: sensorSelectorKey,
    type: ComponentTypes.SENSOR_SELECTOR,
  }
  return sensorSelectorStateDef;
}

DataExplorationChart.propTypes = {
  template: PropTypes.object.isRequired, // we require a (partial) chart definition
  manualRefreshTime: PropTypes.number,   // may be undefined so cannot be marked as required
  owner: PropTypes.string.isRequired,
  unitType: PropTypes.string,
  onAxisUpdateCallback: PropTypes.func,
  chartModifiers: PropTypes.object,
};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: props.stateKey,
      type: ComponentTypes.DATA_EXPLORATION_CHART,
    }
  };
};

function generateLegendItems(state) {
  let items = [];
  _.forEach(['primary', 'secondary'], xAxisId => {
    let axisDefinition = state.definition[xAxisId];
    if (_.isNil(axisDefinition)) {
      return;
    }

    _.forEach(axisDefinition.displayOrder, (refIndex, order) => {
      if (_.isNil(axisDefinition.trucks[refIndex]) ||
        _.isNil(axisDefinition.sensors[refIndex]) ||
        _.isNil(axisDefinition.config[refIndex])) {
        return;
      }

      let stats = _.isNil(state.stats) ? null : state.stats[xAxisId];
      items.push({
        // metadata
        xAxisId: xAxisId,
        refIndex: refIndex,
        order: order,

        // truck and sensor info
        truckName: axisDefinition.trucks[refIndex].name,
        sensorName: axisDefinition.sensors[refIndex].alias,
        sensorUom: axisDefinition.sensors[refIndex].uom,

        // series configuration
        isVisible: axisDefinition.config[refIndex].isVisible,
        yAxisId: axisDefinition.config[refIndex].yAxisId,
        color: axisDefinition.config[refIndex].color,

        // series statistics
        count: _.isNil(stats) || _.isNil(stats[refIndex]) ? '-' : stats[refIndex].count,
        min: _.isNil(stats) || _.isNil(stats[refIndex]) ? '-' : stats[refIndex].min,
        max: _.isNil(stats) || _.isNil(stats[refIndex]) ? '-' : stats[refIndex].max,
        mean: _.isNil(stats) || _.isNil(stats[refIndex]) ? '-' : stats[refIndex].mean,
      });
    });
  });
  return items;
}

const mapStateToProps = (state, props) => {
  const {stateDef} = props;
  let appState = applicationState(state);
  let componentState = dataExplorationChartState(state[stateDef.key]);
  return {
    // required props for chart control
    primaryData: componentState.data.primary,
    secondaryData: componentState.data.secondary,
    primaryDef: componentState.definition.primary,
    definition: componentState.definition,
    data: componentState.data,
    stats: componentState.stats,
    legendItems: _.isNil(componentState) ? [] : generateLegendItems(componentState),
    shouldOpenSensorSelector: componentState.shouldOpenSensorSelector,
    queryRunning: componentState.queryRunning,
    colorPickerState: componentState.colorPickerState,
    isUserAdmin: appState.user.isUserAdmin,
    exportCsvUrl: componentState.exportCsvUrl,
    triggerExportCsv: componentState.triggerExportCsv,
    openChartSettingsPopup: componentState.openChartSettingsPopup,
    chartSettingButtonAnchor: componentState.chartSettingButtonAnchor,
    closeNaNGap: componentState.closeNaNGap,
    isRotated: componentState.isRotated,
    openScreenShotSettingsPopup: componentState.openScreenShotSettingsPopup,
    screenShotButtonAnchor: componentState.screenShotButtonAnchor,
    isDelayedScreenShot: componentState.isDelayedScreenShot,
  };
};

const mapDispatchToProps = (dispatch, props) => {

  let sensorSelectorStateDef = getSensorSelectorStateDef(props);

  return {
    onTemplateChanged: (newTemplate, initialLoad) => {
      dispatch(dataExplorationChartActions.setTemplate(props.stateDef, newTemplate, initialLoad));
    },
    onConfigChangedYAxisId: (xAxisId, refIndex, newYAxisId) => {
      dispatch(dataExplorationChartActions.setConfigYAxis(props.stateDef, xAxisId, refIndex, newYAxisId));
    },
    onConfigChangedColor: (xAxisId, refIndex, newColor) => {
      dispatch(dataExplorationChartActions.setConfigColor(props.stateDef, xAxisId, refIndex, newColor));
    },
    onConfigChangedVisible: (xAxisId, refIndex, newIsVisible) => {
      dispatch(dataExplorationChartActions.setConfigVisible(props.stateDef, xAxisId, refIndex, newIsVisible));
    },
    onAxisChange: (axisId, min, max, onAxisUpdateCallback) => {
      dispatch(dataExplorationChartActions.userPanZoomUpdate(props.stateDef, axisId, min, max));
      if (!_.isNil(onAxisUpdateCallback)) {
        onAxisUpdateCallback(axisId, min, max);
      }
    },
    openSensorSelector: () => {
      dispatch(dataExplorationChartActions.requestOpenSensorSelector(props.stateDef));
    },
    closeSensorSelector: () => {
      dispatch(dataExplorationChartActions.requestCloseSensorSelector(props.stateDef));
    },
    setSelectedSensors: (xAxisId, newSensors) => {
      dispatch(dataExplorationChartActions.setSelectedSensors(props.stateDef, xAxisId, newSensors));
    },
    onSetColorPickerState: (xAxisId, refIndex, origColor) => {
      dispatch(dataExplorationChartActions.setColorPickerState(props.stateDef, xAxisId, refIndex, origColor));
    },
    openCreateSensorGroup: () => {
      dispatch(sensorSelectorActions.openCreateSensorGroup(sensorSelectorStateDef));
    },
    closeCreateSensorGroup: () => {
      dispatch(sensorSelectorActions.closeCreateSensorGroup(sensorSelectorStateDef));
    },
    downloadData: () => {
      dispatch(dataExplorationChartActions.downloadData(props.stateDef))
    },
    queryByChunks: (truck, startTime, endTime, sensors) => {
      dispatch(dataExplorationChartActions.queryByChunks(props.stateDef, truck, startTime, endTime, sensors));
    },
    resetQuerySensorData: () => {
      dispatch(dataExplorationChartActions.resetQuerySensorData(props.stateDef))
    },
    onClickChartSettingsPopup: (chartSettingButtonAnchor)=> {
      dispatch(dataExplorationChartActions.onClickChartSettingsPopup(props.stateDef, chartSettingButtonAnchor));
    },
    onToggleCloseNaNGap: () => {
      dispatch(dataExplorationChartActions.onToggleCloseNaNGap(props.stateDef));
    },
    onRotateAxes: (rotate) => {
      dispatch(dataExplorationChartActions.rotateAxes(props.stateDef, rotate));
    },
    onTakeScreenShotStart: () => {
      dispatch(dataExplorationChartActions.setQueryRunningState(props.stateDef, true));
    },
    onTakeScreenShotSuccess: () => {
      dispatch(dataExplorationChartActions.setQueryRunningState(props.stateDef, false));
      dispatch(appErrorActions.displayError("Screenshot of this chart has been copied to clipboard."));
    },
    onClickScreenShotSettingsPopup: (screenShotButtonAnchor) => {
      dispatch(dataExplorationChartActions.onClickScreenShotSettingsPopup(props.stateDef, screenShotButtonAnchor));
    },
    onToggleDelayedScreenShot: () => {
      dispatch(dataExplorationChartActions.onToggleDelayedScreenShot(props.stateDef));
    },
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(DataExplorationChart));
