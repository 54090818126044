import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from "../../common/factories/queryActionFactory";
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import dataExplorationDisplayActionTypes from './dataExplorationDisplayActionTypes';

import {fetchDataExplorationDisplayData, fetchDataExplorationDisplayDataAge, fetchTruckDetails} from "./dataExplorationDisplayQueries";
import errorMessages from '../../common/errorMessages';

import moment from "moment";
import * as timeService from './services/timeService';

const selectContext = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT, 'stateDef', 'context');

const setTimeFrameDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTimeDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDurationDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION, 'stateDef', 'duration');
const setChartStartTimeDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SET_CHART_START_TIME, 'stateDef', 'chartStartTime');
const clearDataAge = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA, 'stateDef');
const refreshRelativeTime = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE, 'stateDef');

const selectNextTruckStart = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START, 'stateDef');
const selectNextTruckEnd = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END, 'stateDef');

const selectPrevTruckStart = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START, 'stateDef');
const selectPrevTruckEnd = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END, 'stateDef');

const selectDuration = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_DURATION, 'stateDef', 'duration');
const findNext = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_FIND_NEXT_RANGE, 'stateDef');
const findPrevious = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_FIND_PREVIOUS_RANGE, 'stateDef');

const updateChartAxis = defaultActionFactory(
  dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_UPDATE_CHART_AXIS,
  'stateDef',
  'xAxisId',
  'min',
  'max'
);

const selectNextTruck = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectNextTruckStart(stateDef));

    await dispatch(selectNextTruckEnd(stateDef));
  }
}

const selectPrevTruck = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectPrevTruckStart(stateDef));

    await dispatch(selectPrevTruckEnd(stateDef));
  }
}

const queryData = queryActionFactory(
    dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING,
    dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS,
    dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_TRUCKS,
    fetchDataExplorationDisplayData
);

const queryDataAge = queryActionFactory(
  dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING,
  dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS,
  dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR,
  errorMessages.ERROR_RETRIEVING_DATA_AGE,
  fetchDataExplorationDisplayDataAge
);

const queryTruckDetailsStarting = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_STARTING, 'stateDef');
const queryTruckDetailsSuccess = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_SUCCESS, 'stateDef', 'queryResults');
const queryTruckDetailsError = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_ERROR, 'stateDef');

const queryTruckDetails = queryActionCustomFactory(
  queryTruckDetailsStarting,
  queryTruckDetailsSuccess,
  queryTruckDetailsError,
  errorMessages.ERROR_RETRIEVING_EXPORT_DATA_DETAILS,
  fetchTruckDetails
);

const loadDisplay = (stateDef, startTime, endTime) => {
  return async (dispatch, getState) => {
    // Execute display query
    await dispatch(queryData(stateDef));
    await dispatch(selectContext(stateDef, getState()[stateDef.key].selectedContext));
    await dispatch(queryTruckDetails(stateDef, getState()[stateDef.key].selectedContext.truckId, startTime));
  }
};

const setTimeFrame = (stateDef, timeFrame) => {

  return async (dispatch, getState) => {

    await dispatch(setTimeFrameDefaultAction(stateDef, timeFrame));

    if (timeFrame.label !== 'Custom') {
      let endTime = moment().startOf('minute').unix();
      let startTime = timeService.calculateStartTimeUsingTimeFrame(timeFrame);

      await dispatch(loadDisplay(stateDef, startTime, endTime));
    }
  }
}


const setCustomDuration = (stateDef, duration) => {

  return async(dispatch, getState) => {

    await dispatch(setCustomDurationDefaultAction(stateDef, duration));

    let startTime = getState()[stateDef.key].selectedCustomStartTime;
    let endTime = timeService.calculateEndTimeUsingStartTimeAndDuration(startTime, duration);

  }

}

const loadTruckDetails = (stateDef, startTime) => {
  return async (dispatch, getState) => {
    await dispatch(queryTruckDetails(stateDef, getState()[stateDef.key].selectedContext.truckId, startTime));
  }
}

const setCustomStartTime = (stateDef, startTime) => {
  return async(dispatch, getState) => {
    await dispatch(setCustomStartTimeDefaultAction(stateDef, startTime));
  }
}

const setChartStartTime = (stateDef, chartStartTime) => {
    return async(dispatch, getState) => {
        await dispatch(setChartStartTimeDefaultAction(stateDef, chartStartTime));
    }
}
const reloadDataForTruck = (stateDef, selectedContext, selectedDateTime) => {
  return async (dispatch, getState) => {

    //this function trigger by selection of new dateTime, need to update the new selectedDateTime in status
    await dispatch(setCustomStartTimeDefaultAction(stateDef, selectedDateTime));

    const currentDateTime = selectedDateTime.unix();

    //selectedDateTime cannot be in future
    if(currentDateTime <= moment().unix()){
      // it should try reload once it out of portalAppService generated query period by left or right drag
      // it should try reload once input selectedDateTime out of portalAppService generated query period
      if (currentDateTime <= getState()[stateDef.key].queryStartTime || currentDateTime >= getState()[stateDef.key].queryEndTime ){
        await dispatch(queryTruckDetails(stateDef, selectedContext.truckId, currentDateTime));
      }
    }

  }

};

const findNextWithDataReload = (stateDef) => {
  return async (dispatch, getState) => {

    await dispatch(findNext(stateDef));

    const selectedDateTime =  getState()[stateDef.key].selectedCustomStartTime;
    const selectedContext = getState()[stateDef.key].selectedContext;
    let dataRanges = getState()[stateDef.key].dataRanges;

    if(!_.isEmpty(dataRanges)){
      let currentDateTime = selectedDateTime.unix();

      // it should try reload once it reach right end of current dataRange by right arrow click
      if ((currentDateTime >= _.last(dataRanges).endTime)){
        await dispatch(queryTruckDetails(stateDef, selectedContext.truckId, selectedDateTime.unix()));
      }

    }
  }
};

const findPreviousWithDataReload = (stateDef) => {
  return async (dispatch, getState) => {

      await dispatch(findPrevious(stateDef));

      const selectedDateTime =  getState()[stateDef.key].selectedCustomStartTime;
      const selectedContext = getState()[stateDef.key].selectedContext;
      const dataRanges = getState()[stateDef.key].dataRanges;

      if(!_.isEmpty(dataRanges)){
        const currentDateTime = selectedDateTime.unix();

        // it should try reload once it reach left end of current dataRange by left arrow click
        if ((currentDateTime <= _.head(dataRanges).startTime)){
          await dispatch(queryTruckDetails(stateDef, selectedContext.truckId, selectedDateTime.unix()));
        }
      }

    }
};

const setCustomStartTimeDisplay = defaultActionFactory(dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');

export {
  loadDisplay,
  queryData,
  queryDataAge,
  clearDataAge,
  refreshRelativeTime,
  selectContext,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  selectNextTruck,
  selectPrevTruck,
  setCustomStartTimeDisplay,
  selectDuration,
  setChartStartTime,
  reloadDataForTruck,
  findNextWithDataReload,
  findPreviousWithDataReload,
  updateChartAxis,
  loadTruckDetails
}
