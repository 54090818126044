import _ from 'lodash';

import dataExplorationActionTypes from '../dataExplorationDisplayActionTypes';
import { dataExplorationDisplayState } from '../dataExplorationDisplaySelector';
import { prettifyValue } from '../services/truckService';
import moment from "moment";
import * as truckService from "../services/truckService";

import * as dataExplorerService from '../../../../components/controls/dataExplorer/dataExplorerService';

const initialState = dataExplorationDisplayState();

const truckReducer = (state=initialState, action) => {

  switch (action.type) {

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_STARTING:
      return onQueryTruckDetailsStarting(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_SUCCESS:
      return onQueryTruckDetailsSuccess(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_ERROR:
      return onQueryTruckDetailsError(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS:
      let actualTimestamp = action.queryResults.truckDataAge.timestamp;
      let displayTimestamp = prettifyValue(actualTimestamp);
      return { ...state, dataAgeDisplay: displayTimestamp, dataAgeActual: actualTimestamp, queryRunning: false };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR:
      return { ...state, queryRunning: false };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING:
      return { ...state, queryRunning: true };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA:
      return { ...state, dataAgeActual: initialState.dataAgeActual, dataAgeDisplay:  initialState.dataAgeDisplay };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE:
      let refreshDisplayTimestamp = prettifyValue(state.dataAgeActual);
      return { ...state, dataAgeDisplay: refreshDisplayTimestamp };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT:
      return onSelectContext(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME:
      return onSetTimeFrame(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME:
      return onSetStartTime(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION:
      return onSetDuration(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START:
    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START:
      return { ...state, queryRunning: true };

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END:
      return onSelectNextContext(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END:
      return onSelectPrevContext(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY:
      return onSetStartTimeDisplay(state, action);
    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_FIND_NEXT_RANGE:
      return onFindNext(state, action);
    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_FIND_PREVIOUS_RANGE:
      return onFindPrevious(state, action);

    case dataExplorationActionTypes.DATA_EXPLORATION_DISPLAY_UPDATE_CHART_AXIS:
      // Update the state with the new axis values
      return {
        ...state,
        chartAxis: {
          ...state.chartAxis,
          [action.xAxisId]: { min: action.min, max: action.max },
        },
      };
    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {
  let contextDetails = truckService.processTrucks(action.queryResults.dataExplorationDisplayAllTrucks, state.appliedFilters);
  let newState = {
    ...state,
    queryRunning: false,
    trucks: action.queryResults.dataExplorationDisplayAllTrucks,
    contexts: contextDetails.contexts,
    filters: contextDetails.filters,
  }

  // If the currently applied filters are not applicable to the new results from the query
  // then clear out the applied filters
  if (_.isEmpty(contextDetails.contexts)) {
    return {
      ...newState,
      appliedFilters: [],
    }
  }

  return newState;

};

const onSelectContext = (state, action) => {
  if (!_.isNil(action.context) && _.isEmpty(action.context)) {

    // The AutoComplete control we use will output an empty array when using the keyboard to clear
    // out the selected value. Catch that here and set it to null so the display behaves properly.

    return {...state, selectedContext: null };
  }

  let selectedContext = truckService.resolveSelectedContext(
    state.contexts,
    _.isNil(action.context) ? action.context : action.context.truckId
  );
  return {...state, queryRunning: false, selectedContext: selectedContext };
};

const onSetTimeFrame = (state, action) => {

  let newState = {
    ...state,
    selectedTimeFrame: action.timeFrame,
    selectedCustomStartTime: initialState.selectedCustomStartTime,
    selectedCustomDuration: initialState.selectedCustomDuration,
    selectedCustomStartTimeDisplay: initialState.selectedCustomStartTimeDisplay,
  };

  // If the time frame is set to custom, set the initial start time to the now.

  if (newState.selectedTimeFrame.label === 'Custom') {

    let defaultTime = moment().subtract(initialState.selectedCustomDuration.value, 'minutes').startOf('minute');

    newState = {
      ...newState,
      selectedCustomStartTime: defaultTime,
      selectedCustomStartTimeDisplay: defaultTime,
    }
  }

  return newState;
};

const onSetStartTime = (state, action) => {

  return {
    ...state,
    selectedCustomStartTime: action.startTime,
    selectedCustomStartTimeDisplay: action.startTime
  }
};

const onSetDuration = (state, action) => {

  return {
    ...state,
    selectedCustomDuration: action.duration
  }
};

const onSelectNextContext = (state, action) => {
  let contextId = state.selectedContext.truckId;
  let selectedIndex = state.contexts.findIndex(p=>p.truckId===state.selectedContext.truckId);
  
  if(selectedIndex < state.contexts.length-1){
    ++selectedIndex;
    contextId = state.contexts[selectedIndex].truckId
  }

  return onSelectContext(state, { context: { truckId: contextId } } );
};

const onSelectPrevContext = (state, action) => {
  let contextId = state.selectedContext.truckId;
  let selectedIndex = state.contexts.findIndex(p=>p.truckId===state.selectedContext.truckId);
  
  if(selectedIndex > 0){
    --selectedIndex;
    contextId = state.contexts[selectedIndex].truckId
  }
  
  return onSelectContext(state, { context: { truckId: contextId } } );
};

const onSetStartTimeDisplay = (state, action) => {

  return {
    ...state,
    selectedCustomStartTimeDisplay: action.startTime
  }
}

const onQueryTruckDetailsStarting = (state, action) => {

  const newState = {
    ...state,
    truckDetailsQueryRunning: true,
    dataRanges: [],
  }

  return {
    ...newState,
    canExport: true
  };
};

const onQueryTruckDetailsError = (state, action) => {
  return {
    ...state,
    truckDetailsQueryRunning: false,
    dataRanges: [],
  };
};

const onQueryTruckDetailsSuccess = (state, action) => {
  let newState = {
    ...state,
    truckDetailsQueryRunning: false,
    dataRanges: action.queryResults.dataExplorationTruckDetails.availabilityBlocks,

    // Flags to indicate current query range
    // In theory there is potential bug for scenario:
    // "If a truck has sensor data before 2011-01-01, and after 2011-12-01. and if we input "start time" of 2021-03-01,
    // then on GUI display it will not show right arrow to indicate there is more data on right. "
    // But we don't find any real data like that yet, so decision is to do enhancement when there is real case reported.
    // One potential solution: enhance PortalAppService to return 2 more fields to indicate data range in DB (e.g. dataAvailableStartTime, dataAvailableEndTime),
    // then replace those 2 new fields with the 2 below.
    queryStartTime: action.queryResults.dataExplorationTruckDetails.startTime,
    queryEndTime: action.queryResults.dataExplorationTruckDetails.endTime

  };
  return newState;
};
const onFindNext = (state, action) => {

  let nextEdge = dataExplorerService.getNextBlockEdge(state.selectedCustomStartTime, state.dataRanges, state.selectedCustomDuration.value);

  let newState = {
    ...state,
    selectedCustomStartTime: _.isNil(nextEdge) ? state.selectedCustomStartTime : nextEdge,
    selectedCustomStartTimeDisplay: _.isNil(nextEdge) ? state.selectedCustomStartTime : nextEdge,
    selectedChartStartTime: _.isNil(nextEdge) ? state.selectedChartStartTime : nextEdge,
  };
  return newState;
};

const onFindPrevious = (state, action) => {
  let previousEdge = dataExplorerService.getPreviousBlockEdge(state.selectedCustomStartTime, state.dataRanges);

  let newState = {
    ...state,
    selectedCustomStartTime: _.isNil(previousEdge) ? state.selectedCustomStartTime : previousEdge,
    selectedCustomStartTimeDisplay: _.isNil(previousEdge) ? state.selectedCustomStartTime : previousEdge,
    selectedChartStartTime: _.isNil(previousEdge) ? state.selectedChartStartTime : previousEdge,
  };
  return newState;
};

export default truckReducer
