import reduceReducers from 'reduce-reducers';

import { dataExplorationDisplayStateV1 } from './dataExplorationDisplaySelectorV1';
import truckReducerV1 from './reducers/truckReducerV1';
import filterReducer from './reducers/filterReducer';

const initialState = dataExplorationDisplayStateV1();

const dataExplorationDisplayReducerV1 = (state=initialState, action) => {

  const reducer = reduceReducers(
    truckReducerV1,
    filterReducer
  );

  return reducer(state, action);

};

export default dataExplorationDisplayReducerV1