import _ from 'lodash';

import ComponentTypes from '../components/componentTypes';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/List';

import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import {
    Box,
    Checkbox,
    Collapse,
    Divider,
    FormControlLabel,
    Grid,
    Hidden,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import CategoryIcon from '@mui/icons-material/Category';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AlarmsIcon from '@mui/icons-material/Alarm'
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {AutoCompleteMDT} from '../components/controls/mdtMuiControls';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {ExpandLess, ExpandMore, Spoke} from "@mui/icons-material";
import React from "react";
import {GROUP_NAME_FAVORITES} from "../state/app/services/routingService";
import BusinessIcon from '@mui/icons-material/Business';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AltRouteIcon from '@mui/icons-material/AltRoute';

/**
 * Returns the menu items for the main navigation menu (drawer).
 *
 * This method is called on every render pass of the application Layout component
 *
 */

const renderNavMenu = (props, styles) => {

    let menuGroups = [];
    if (!_.isNil(props.userRoutes)) {
        menuGroups = resolveRoutesToMenuGroups(props.userRoutes, props, styles);
    }

    return (
        <Grid container direction={'column'} style={{height: '110%'}}>
            <Hidden smUp>
                <Grid sx={styles.drawerTitleBar}>
                    <Grid item xs={9}>
                        <Typography variant='subtitle1'>MDT Cloud Portal</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent={'flex-end'}>
                            <IconButton
                                aria-owns={props.userMenuTargetElement ? 'layout-menu' : null}
                                aria-haspopup='true'
                                onClick={(event) => {
                                    props.onOpenUserMenu(event.currentTarget)
                                }}
                                size="large"
                            >
                                <AccountCircleIcon sx={styles.userIcon}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider/>
                {
                    props.ownersVisible &&
                    props.userInfoLoaded &&
                    <ListItem sx={styles.drawerOwnerMenu}>
                        <ListItemIcon><DomainIcon/></ListItemIcon>
                        <AutoCompleteMDT
                            options={props.owners}
                            value={props.selectedOwner}
                            onChange={(event, value, reason) => {
                                props.setOwner(value);
                                props.onCloseDrawer();
                            }
                            }
                            noOptionsText={'No owners found...'}
                        />
                    </ListItem>
                }
                {
                    props.ownersVisible &&
                    props.userInfoLoaded &&
                    <Divider/>
                }
            </Hidden>
            <Hidden mdUp>
                {
                    props.hasViewPreReleaseContentPermission === true &&
                    <ListItem sx={styles.drawerShowContentItem}>
                        <FormControlLabel
                            control={<Checkbox color={'default'} checked={props.showPreReleaseContent}
                                               onChange={(event, checked) => {
                                                   props.onShowPreReleaseContent(checked)
                                               }}/>}
                            label={<Typography variant='body2'>Show Pre-Release Content'</Typography>}
                        />
                    </ListItem>
                }
                {
                    props.hasViewPreReleaseContentPermission === true &&
                    <Divider/>
                }
            </Hidden>

            {
                menuGroups.map(menuGroup => {
                    return (
                        <div key={menuGroup.value}>
                            {menuGroup.component}
                            {menuGroup.menu}
                        </div>
                    )
                })
            }
        </Grid>
    );
};

/**
 * Converts the route configuration into a ordered list of menu groups, each
 * containing their ordered menu items.
 */
const resolveRoutesToMenuGroups = (routes, props, styles) => {
    let menuGroups = [];
    routes.groups.forEach((group) => {
        menuGroups.push({
            ...group,
            component: group.value === GROUP_NAME_FAVORITES ?
                renderNavGroup(group, props, styles) :
                renderNavGroupWithExpand(group, props, styles), //the 'favorite' group not collapsible
        });
    });

    menuGroups.forEach((menuGroup, index) => {
        if (menuGroup.value === GROUP_NAME_FAVORITES && routes.routes.findIndex(i => i.group === GROUP_NAME_FAVORITES) === -1) {
            // when no favorites selected, instruction shown instead of menuList
            menuGroup.menu = (
                <Collapse in={routes.groups[index].expand} timeout="auto">
                    {renderFavoritesNotFound(styles)}
                </Collapse>);
        } else {
            menuGroup.menu = (
                <Collapse in={routes.groups[index].expand} timeout="auto">
                    <MenuList sx={styles.drawerMenuList}>
                        {resolveGroupMenuList(routes, menuGroup, props, styles)}
                    </MenuList>
                </Collapse>);

        }
    });

    return _.orderBy(menuGroups, ['order', 'label'], ['asc', 'asc']);
};

/**
 * Generates the menu for a given group from the route configuration.
 */
const resolveGroupMenuList = (routes, menuGroup, props, styles) => {
    let menuItems = [];
    routes.routes.forEach((route) => {
        if (route.group === menuGroup.value) {
            menuItems.push({
                ...route,
                component: renderNavItem(route.label, route.value, mapRouteToIcon(route), props, styles, route.group === GROUP_NAME_FAVORITES),
            });
        }
    });
    menuItems = _.orderBy(menuItems, ['order', 'label'], ['asc', 'asc']);
    return (
        menuItems.map(menuItem => {
            return (menuItem.component)
        })
    );
};

/**
 * Renders a menu group using the provided information.
 */
const renderNavGroup = (group, props, styles) => {
    return (
        <MenuItem sx={styles.drawerGroup}>
            <Typography sx={styles.drawerGroupItem} variant='subtitle1'>{group.label}</Typography>
        </MenuItem>
    )
};

/**
 * Renders a collapsible menu group using the provided information.
 */
const renderNavGroupWithExpand = (group, props, styles) => {
    return (
        <MenuItem
            sx={styles.drawerGroup}
            onClick={() => handleMenuClick(group, props)}
            disableRipple
        >
            <Typography sx={styles.drawerGroupItem} variant='subtitle1'>{group.label}</Typography>
            {group.expand ? <ExpandLess/> : <ExpandMore/>}
        </MenuItem>
    )
};

const handleMenuClick = (group, props) => {
    props.onUpdateUserRoutes(group.value, !group.expand);
}

const handlePinClick = (props, targetPage, pin) => {
    let pinPages = props.userRoutes.pinPages;
    const pageIndex = pinPages.indexOf(targetPage);
    if (pin && pageIndex === -1) {
        pinPages.push(targetPage);
    } else if (!pin && pageIndex !== -1) {
        pinPages.splice(pageIndex, 1)
    } else {
        return;
    }
    props.onUpdateUserConfigForMenu(pinPages);
}

const renderFavoritesNotFound = (styles) => {
    return (
        <Box>
            <Typography sx={styles.menuItemNotFoundTitle} align={'center'}>No Favorites Found</Typography>
            <Typography sx={styles.menuItemNotFoundBody} align={'center'}>Select from below menu to add to your
                Favorites</Typography>
        </Box>
    )
}

/**
 * Renders a menu item using the provided information.
 */

const renderNavItem = (name, url, icon, props, styles, isFavoriteGroup) => {

    const pinButton = isFavoriteGroup ?
        <BookmarkRemoveIcon/> :
        props.userRoutes.pinPages.includes(name) ? null : <BookmarkAddIcon/>; // only show bookmark add icon when it's not added yet

    return (
        <MenuItem sx={styles.menuItem} key={url} onClick={() => {
            props.navigate(url);
            props.onCloseDrawer()
        }} disableRipple={true}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={<Typography variant={'subtitle1'}>{name}</Typography>}/>
            <IconButton
                size="small"
                className="pin-icon"
                aria-label="Pin"
                label="Pin"
                onClick={(event) => {
                    event.stopPropagation();
                    handlePinClick(props, name, !isFavoriteGroup);
                }}
            >
                {pinButton}
            </IconButton>
        </MenuItem>
    )
};

/**
 * Maps route to an icon that is displayed in the app drawer menu.
 */
const mapRouteToIcon = (route) => {
    let icon = null;
    if (!_.isNil(route)) {
        switch (route.type) {
            case ComponentTypes.EXPORT:
                icon = <CloudDownloadIcon/>;
                break;
            case ComponentTypes.USER_MANAGEMENT:
                icon = <PeopleIcon/>;
                break;
            case ComponentTypes.FLEET_MANAGEMENT:
                icon = <LocalShippingIcon/>;
                break;
            case ComponentTypes.ROUTE_ASSET_MANAGEMENT:
                icon = <AltRouteIcon />;
                break;
            case ComponentTypes.CUSTOMERS_DISPLAY:
                icon = <GroupsIcon/>;
                break;
            case ComponentTypes.WELLS_DISPLAY:
                icon = <Spoke/>;
                break;
            case ComponentTypes.EQUIPMENT_LIST:
            case ComponentTypes.SYSTEM_SOFTWARE:
            case ComponentTypes.COMPONENT_HOURS:
            case ComponentTypes.AUDIT_LOGS:
                icon = <ListIcon/>;
                break;
            case ComponentTypes.FLEET_DASHBOARD:
            case ComponentTypes.PUMP_DASHBOARD:
            case ComponentTypes.DFP_DASHBOARD:
            case ComponentTypes.FLEET_OVERVIEW:
            case ComponentTypes.COIL_DASHBOARD:
            case ComponentTypes.JOB_OVERVIEW:
            case ComponentTypes.ASSET_TRACKING:
                icon = <DashboardIcon/>;
                break;
            case ComponentTypes.DOWNLOADS:
                icon = <SystemUpdateAltIcon/>;
                break;
            case ComponentTypes.DATA_EXPLORATION_DISPLAY_V1:
            case ComponentTypes.DATA_EXPLORATION_DISPLAY:
                icon = <ShowChartIcon/>;
                break;
            case ComponentTypes.CLIENT_STATUS:
                icon = <SettingsRemoteIcon/>;
                break;
            case ComponentTypes.COMPONENTS:
                icon = <CategoryIcon/>;
                break;
            case ComponentTypes.SETTINGS:
                icon = <SettingsIcon/>;
                break;
            case ComponentTypes.ALARMS_DISPLAY:
                icon = <AlarmsIcon/>;
                break;
            case ComponentTypes.SUBSCRIPTIONS_DISPLAY:
                icon = <NotificationsIcon/>;
                break;
            case ComponentTypes.OWNER_MANAGEMENT:
                icon = <BusinessIcon />;
                break;
            case ComponentTypes.JOB_HISTORY:
                icon = <WorkHistoryIcon />;
                break;
            default:
                icon = null;
        }
    }
    return icon;
};

export {
    renderNavMenu
};
