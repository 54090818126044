export default {

  LIVE_VIEW_QUERY_TRUCKS_STARTING: 'LIVE_VIEW_QUERY_TRUCKS_STARTING',
  LIVE_VIEW_QUERY_TRUCKS_SUCCESS: 'LIVE_VIEW_QUERY_TRUCKS_SUCCESS',
  LIVE_VIEW_QUERY_TRUCKS_ERROR: 'LIVE_VIEW_QUERY_TRUCKS_ERROR',

  LIVE_VIEW_SET_TIME_FRAME: 'LIVE_VIEW_SET_TIME_FRAME',

  LIVE_VIEW_SET_UNIT_TYPE: 'LIVE_VIEW_SET_UNIT_TYPE',

  LIVE_VIEW_SET_TRUCK_FILTER: 'LIVE_VIEW_SET_TRUCK_FILTER',

  LIVE_VIEW_SET_SENSOR_SELECTOR_OPEN: 'LIVE_VIEW_SET_SENSOR_SELECTOR_OPEN',
  LIVE_VIEW_SET_SELECTED_SENSORS: 'LIVE_VIEW_SET_SELECTED_SENSORS',

  LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_STARTING: 'LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_STARTING',
  LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_SUCCESS: 'LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_SUCCESS',
  LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_ERROR: 'LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_ERROR',

  LIVE_VIEW_DEFINITION_SET_SENSOR: 'LIVE_VIEW_DEFINITION_SET_SENSOR',
  LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY: 'LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY', // Hide One
  LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_ALL_OTHERS:   'LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_ALL_OTHERS', // Hide All Others
  LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_OTHER_AFTER:  'LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_OTHER_AFTER', // Hide All to the Right

  LIVE_VIEW_CONFIGURE_CHART: 'LIVE_VIEW_CONFIGURE_CHART',
  LIVE_VIEW_CONFIGURE_SET_CONTEXT_COLOR: 'LIVE_VIEW_CONFIGURE_SET_CONTEXT_COLOR',
  LIVE_VIEW_CONFIGURE_CHART_SELECT_TAB: 'LIVE_VIEW_CONFIGURE_CHART_SELECT_TAB',

  LIVE_VIEW_CONFIGURE_ADD_DATA_RULE: 'LIVE_VIEW_CONFIGURE_ADD_DATA_RULE',
  LIVE_VIEW_CONFIGURE_REMOVE_DATA_RULE: 'LIVE_VIEW_CONFIGURE_REMOVE_DATA_RULE',
  LIVE_VIEW_CONFIGURE_DATA_RULE_SET_COLOR_PICKER_STATE: 'LIVE_VIEW_CONFIGURE_DATA_RULE_SET_COLOR_PICKER_STATE',  
  LIVE_VIEW_CONFIGURE_MOVE_SENSOR: 'LIVE_VIEW_CONFIGURE_MOVE_SENSOR',
  LIVE_VIEW_CONFIGURE_SET_SENSOR_DISPLAY_NAME: 'LIVE_VIEW_CONFIGURE_SET_SENSOR_DISPLAY_NAME',
  LIVE_VIEW_CONFIGURE_SET_SENSOR_UOM: 'LIVE_VIEW_CONFIGURE_SET_SENSOR_UOM',
  LIVE_VIEW_CONFIGURE_DATA_RULE_SET_PROPERTY: 'LIVE_VIEW_CONFIGURE_DATA_RULE_SET_PROPERTY',
  LIVE_VIEW_CONFIGURE_CLOSE_GAPS: 'LIVE_VIEW_CONFIGURE_CLOSE_GAPS',

  LIVE_VIEW_SET_FULLSCREEN_OPTION: 'LIVE_VIEW_SET_FULLSCREEN_OPTION',

  LIVE_VIEW_HIGHLIGHT_TRUCK: 'LIVE_VIEW_HIGHLIGHT_TRUCK',

  LIVE_VIEW_DOWNLOAD_DATA: 'LIVE_VIEW_DOWNLOAD_DATA',

  LIVE_VIEW_XAXIS_VISIBLE_RANGE_CHANGED: 'LIVE_VIEW_XAXIS_VISIBLE_RANGE_CHANGED',
  LIVE_VIEW_YAXIS_VISIBLE_RANGE_CHANGED: 'LIVE_VIEW_YAXIS_VISIBLE_RANGE_CHANGED',

  LIVE_VIEW_CREATE_SUBSCRIPTION: 'LIVE_VIEW_CREATE_SUBSCRIPTION',
  LIVE_VIEW_CLOSE_SUBSCRIPTION: 'LIVE_VIEW_CLOSE_SUBSCRIPTION',
  LIVE_VIEW_PAUSE_SUBSCRIPTION: 'LIVE_VIEW_PAUSE_SUBSCRIPTION',
  LIVE_VIEW_SET_DATA_DISPLAY_MODE_OPTION: 'LIVE_VIEW_SET_DATA_DISPLAY_MODE_OPTION',

  LIVE_VIEW_RESET: 'LIVE_VIEW_RESET',

  LIVE_VIEW_USER_CONFIGURATION_SAVE_STARTING: 'LIVE_VIEW_USER_CONFIGURATION_SAVE_STARTING',
  LIVE_VIEW_USER_CONFIGURATION_SAVE_SUCCESS: 'LIVE_VIEW_USER_CONFIGURATION_SAVE_SUCCESS',
  LIVE_VIEW_USER_CONFIGURATION_SAVE_ERROR: 'LIVE_VIEW_USER_CONFIGURATION_SAVE_ERROR',

  LIVE_VIEW_ON_ROLLOVER: 'LIVE_VIEW_ON_ROLLOVER',
  LIVE_VIEW_TOGGLE_LEGEND: 'LIVE_VIEW_TOGGLE_LEGEND',

  LIVE_VIEW_DEFINITION_SET_FLEET_NAME: 'LIVE_VIEW_DEFINITION_SET_FLEET_NAME',
}