import _ from "lodash";
import moment from "moment";

const calculateStartTimeUsingTimeFrame = (timeFrame) => {
  if (!_.isNil(timeFrame)) {
    return moment().subtract(timeFrame.value, 'minutes').startOf('minute').unix();
  }
}

const calculateEndTimeUsingStartTimeAndDuration = (startTime, duration) => {
  if (!_.isNil(startTime) && !_.isNil(duration)) {
    // Make a copy so we aren't modifying the original
    let selectedCustomStartTime = moment(startTime);
    return selectedCustomStartTime.add(duration.value, 'minutes').startOf('minute').unix();
  }
}

const calculateSensorMinutes = (sensorCount, durationMinutes) => {

  if (_.isNil(sensorCount) || _.isNil(durationMinutes)) {
    return 0;
  }

  return sensorCount * durationMinutes;
};

const hasExceededMaximumDuration = (state) => {

  if (_.isEmpty(state.selectedSensors)) {
    return false;
  }

  if (_.isNil(state.selectedDuration) || _.isNil(state.selectedDuration.value)) {
    return false;
  }

  return calculateSensorMinutes(state.selectedSensors.length, state.selectedDuration.value) > MAXIMUM_SENSOR_MINUTES;
};

const getMaximumDurationLabel = (state) => {

  const durations = initialState.durations;

  if (!hasExceededMaximumDuration(state)) {
    return (durations[durations.length-1].value / 60).toString();
  }

  // Try and find a duration that works for the current truck selection

  // Use all sensors if none are selected via the sensor selector
  const sensors = (_.isNil(state.selectedSensors) || _.isEmpty(state.selectedSensors)) ? state.sensors : state.selectedSensors;

  for (let len=durations.length, i=len-1; i>=0; i--) {
    let duration = durations[i];
    if (calculateSensorMinutes(sensors.length, duration.value) <= MAXIMUM_SENSOR_MINUTES) {
      return duration.label;
    }
  }

  return null;
};
export {
  calculateStartTimeUsingTimeFrame,
  calculateEndTimeUsingStartTimeAndDuration,
  hasExceededMaximumDuration,
  getMaximumDurationLabel
}