import _ from 'lodash';
import history from '../../helpers/historyHelper';
import {connectRouter} from 'connected-react-router';
import reducerMapFactory from './factories/reducerMapFactory';
import ComponentTypes from '../../components/componentTypes';
import rootActionTypes from './rootActionTypes';

// Main app reducer
import appReducer, {initialState as appInitialState} from '../app/appReducer';

// Display reducers
import exportReducer from '../displays/export/exportReducer';
import userMgmtReducer from '../displays/userMgmt/userMgmtReducer';
import routeAssetMgmtReducer from '../displays/routeAssetManagement/routeAssetMgmtReducer';
import equipmentListReducer from '../displays/equipmentList/equipmentListReducer';
import pumpDashboardReducer from '../displays/pumpDashboard/pumpDashboardReducer';
import dfpDashboardReducer from '../displays/dfpDashboard/dfpDashboardReducer';
import clientStatusReducer from '../displays/clientStatus/clientStatusReducer';
import systemSoftwareReducer from '../displays/systemSoftware/systemSoftwareReducer';
import componentsReducer from '../displays/componentsDisplay/componentsReducer';
import settingsReducer from '../displays/settings/reducers/settingsReducer';
import fleetMapReducer from '../displays/fleetMap/display/fleetMapReducer';
import fracFleetMapReducer from '../displays/fleetMap/maps/frac/fracFleetMapReducer';
import pumpOnlyFleetMapReducer from '../displays/fleetMap/maps/pumpOnly/pumpOnlyFleetMapReducer';
import componentHoursReducer from '../displays/componentHours/componentHoursReducer';
import alarmsReducer from '../displays/alarms/alarmsReducer';
import fleetOverviewReducer from '../displays/fleetOverview/fleetOverviewReducer';
import dgbFleetMapReducer from '../displays/fleetMap/maps/dgb/dgbFleetMapReducer';
import coilDashboardReducer from '../displays/coilDashboard/coilDashboardReducer';
import dataExplorationDisplayReducerV1 from '../displays/dataExplorationDisplay/dataExplorationDisplayReducerV1';
import dataExplorationDisplayReducer from '../displays/dataExplorationDisplay/dataExplorationDisplayReducer';
import jobOverviewReducer from '../displays/jobOverview/jobOverviewReducer';
import ownerManagementReducer from '../displays/ownerManagement/ownerManagementReducer';
import jobHistoryReducer from '../displays/jobHistory/jobHistoryReducer';
import wellsDisplayReducer from "../displays/wellsDisplay/wellsDisplayReducer";
import auditLogsReducer from "../displays/auditLogs/auditLogsReducer";
import assetTrackingReducer from '../displays/assetTracking/assetTrackingReducer';

import liveViewReducer from '../displays/fleetMap/maps/liveView/liveViewReducer';

// Card reducers
import unitHoursReducer from '../cards/unitHours/unitHoursReducer';
import unitActivityReducer from '../cards/unitActivity/unitActivityReducer';
import fleetActivityReducer from '../cards/fleetActivity/fleetActivityReducer';
import unitFleetReducer from '../cards/unitFleet/unitFleetReducer';
import fleetPumpStatusReducer from '../cards/fleetPumpStatus/fleetPumpStatusReducer';
import fleetPRCHistoryReducer from '../cards/fleetPRCHistory/fleetPRCHistoryReducer';
import fleetClientStatusReducer from '../cards/fleetClientStatus/fleetClientStatusReducer';
import componentLifeReducer from '../cards/componentLife/componentLifeReducer';
import alarmCountReducer from '../cards/alarmCount/alarmCountReducer';
import unitChartReducer from '../cards/unitChart/unitChartReducer';
import fracOverviewCardReducer from '../cards/fracOverviewCard/fracOverviewCardReducer';
import fleetComponentReducer from '../cards/fleetComponents/fleetComponentReducer';
import fleetOperationReducer from '../cards/fleetOperation/fleetOperationReducer';
import fleetVibrationReducer from '../cards/fleetVibration/fleetVibrationReducer';
import componentLifeRoadsideReducer from "../cards/componentLifeRoadside/componentLifeRoadsideReducer";
import componentLifeCurbsideReducer from "../cards/componentLifeCurbside/componentLifeCurbsideReducer";
import unitHoursDfpReducer from '../cards/unitHoursDfp/unitHoursDfpReducer';
import unitActivityDfpReducer from '../cards/unitActivityDfp/unitActivityDfpReducer';
import unitUserChartReducer from '../cards/unitUserChart/unitUserChartReducer';
import dataGridReducer from '../cards/dataGrid/dataGridReducer';
import jobStageChartReducer from '../cards/job/stageChart/jobStageChartReducer';
import auditLogReducer from '../cards/auditLog/auditLogReducer';

// Common
import dataExplorationChartReducer from "./dataExplorationChart/dataExplorationChartReducer";
import sensorSelectorReducer from './sensorSelector/sensorSelectorReducer';
import iconPickerReducer from "./iconPicker/iconPickerReducer";

// Notification reducers
import subscriptionDialogReducer from './notifications/subscriptionDialog/subscriptionDialogReducer';
import providerDialogReducer from '../displays/settings/dialog/providerDialog/providerDialogReducer';
import deviceDialogReducer from '../displays/settings/dialog/deviceDialog/deviceDialogReducer';
import subscriptionsDisplayReducer from '../displays/subscriptionsDisplay/subscriptionsDisplayReducer';
import fleetMgmtReducer from "../displays/fleetManagement/fleetMgmtReducer";
import customersDisplayReducer from "../displays/customersDisplay/customersDisplayReducer";
import downloadsReducer from "../displays/downloads/downloadsDisplayReducer";
import fracJobOverviewCardReducer from "../cards/fracJobOverviewCard/fracJobOverviewCardReducer";

const reducerMap = reducerMapFactory([

    // Displays
    {type: ComponentTypes.EXPORT, reducer: exportReducer},
    {type: ComponentTypes.USER_MANAGEMENT, reducer: userMgmtReducer},
    {type: ComponentTypes.ROUTE_ASSET_MANAGEMENT, reducer: routeAssetMgmtReducer},
    {type: ComponentTypes.EQUIPMENT_LIST, reducer: equipmentListReducer},
    {type: ComponentTypes.PUMP_DASHBOARD, reducer: pumpDashboardReducer},
    {type: ComponentTypes.DFP_DASHBOARD, reducer: dfpDashboardReducer},
    {type: ComponentTypes.CLIENT_STATUS, reducer: clientStatusReducer},
    {type: ComponentTypes.SYSTEM_SOFTWARE, reducer: systemSoftwareReducer},
    {type: ComponentTypes.COMPONENTS, reducer: componentsReducer},
    {type: ComponentTypes.SETTINGS, reducer: settingsReducer},
    {type: ComponentTypes.FLEET_DASHBOARD, reducer: fleetMapReducer},
    {type: ComponentTypes.FRAC_FLEET_MAP, reducer: fracFleetMapReducer},
    {type: ComponentTypes.FRAC_FLEET_MAP_VIEW, reducer: fracFleetMapReducer},
    {type: ComponentTypes.PUMP_ONLY_FLEET_MAP, reducer: pumpOnlyFleetMapReducer},
    {type: ComponentTypes.PUMP_ONLY_FLEET_MAP_VIEW, reducer: pumpOnlyFleetMapReducer},
    {type: ComponentTypes.COMPONENT_HOURS, reducer: componentHoursReducer},
    {type: ComponentTypes.ALARMS_DISPLAY, reducer: alarmsReducer},
    {type: ComponentTypes.FLEET_OVERVIEW, reducer: fleetOverviewReducer},
    {type: ComponentTypes.FLEET_MAP_DGB_VIEW, reducer: dgbFleetMapReducer},
    {type: ComponentTypes.COIL_DASHBOARD, reducer: coilDashboardReducer},
    {type: ComponentTypes.DATA_EXPLORATION_DISPLAY_V1, reducer: dataExplorationDisplayReducerV1},
    {type: ComponentTypes.DATA_EXPLORATION_DISPLAY, reducer: dataExplorationDisplayReducer},
    {type: ComponentTypes.JOB_OVERVIEW, reducer: jobOverviewReducer},
    {type: ComponentTypes.CUSTOMERS_DISPLAY, reducer: customersDisplayReducer},
    {type: ComponentTypes.WELLS_DISPLAY, reducer: wellsDisplayReducer},
    {type: ComponentTypes.FLEET_MANAGEMENT, reducer: fleetMgmtReducer},
    {type: ComponentTypes.DOWNLOADS, reducer: downloadsReducer},
    {type: ComponentTypes.OWNER_MANAGEMENT, reducer: ownerManagementReducer},
    {type: ComponentTypes.JOB_HISTORY, reducer: jobHistoryReducer},
    {type: ComponentTypes.AUDIT_LOGS, reducer: auditLogsReducer},
    {type: ComponentTypes.ASSET_TRACKING, reducer: assetTrackingReducer},

    {type: ComponentTypes.FLEET_LIVE_VIEW, reducer: liveViewReducer},

    // Cards
    {type: ComponentTypes.UNIT_HOURS, reducer: unitHoursReducer},
    {type: ComponentTypes.UNIT_ACTIVITY, reducer: unitActivityReducer},
    {type: ComponentTypes.FLEET_ACTIVITY, reducer: fleetActivityReducer},
    {type: ComponentTypes.UNIT_FLEET, reducer: unitFleetReducer},
    {type: ComponentTypes.FLEET_PUMP_STATUS, reducer: fleetPumpStatusReducer},
    {type: ComponentTypes.FLEET_PRC_HISTORY, reducer: fleetPRCHistoryReducer},
    {type: ComponentTypes.FLEET_CLIENT_STATUS, reducer: fleetClientStatusReducer},
    {type: ComponentTypes.COMPONENT_LIFE, reducer: componentLifeReducer},
    {type: ComponentTypes.COMPONENT_LIFE_CURBSIDE, reducer: componentLifeCurbsideReducer},
    {type: ComponentTypes.COMPONENT_LIFE_ROADSIDE, reducer: componentLifeRoadsideReducer},
    {type: ComponentTypes.FLEET_ALARM_COUNT, reducer: alarmCountReducer},
    {type: ComponentTypes.UNIT_ALARM_COUNT, reducer: alarmCountReducer},
    {type: ComponentTypes.UNIT_CHART_ENGINE, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_TRANSMISSION, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_POWER_END, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_LOCKUP, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_COIL_DETAIL, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_COIL, reducer: unitChartReducer},
    {type: ComponentTypes.FRAC_OVERVIEW_CARD, reducer: fracOverviewCardReducer},
    {type: ComponentTypes.FRAC_JOB_OVERVIEW_CARD, reducer: fracJobOverviewCardReducer},
    {type: ComponentTypes.FLEET_COMPONENTS, reducer: fleetComponentReducer},
    {type: ComponentTypes.FLEET_OPERATION, reducer: fleetOperationReducer},
    {type: ComponentTypes.FLEET_VIBRATION, reducer: fleetVibrationReducer},
    {type: ComponentTypes.UNIT_CHART_PRESSURE_HISTORY, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_OUTPUT_HISTORY, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_ROADSIDE_PRESSURE_HISTORY, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_CURBSIDE_PRESSURE_HISTORY, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_CHART_VIBRATION_HISTORY, reducer: unitChartReducer},
    {type: ComponentTypes.UNIT_HOURS_CURBSIDE, reducer: unitHoursDfpReducer},
    {type: ComponentTypes.UNIT_HOURS_ROADSIDE, reducer: unitHoursDfpReducer},
    {type: ComponentTypes.UNIT_ACTIVITY_CURBSIDE, reducer: unitActivityDfpReducer},
    {type: ComponentTypes.UNIT_ACTIVITY_ROADSIDE, reducer: unitActivityDfpReducer},
    {type: ComponentTypes.UNIT_USER_CHART, reducer: unitUserChartReducer},
    {type: ComponentTypes.DATA_GRID, reducer: dataGridReducer},
    {type: ComponentTypes.JOB_STAGE_CHART, reducer: jobStageChartReducer},
    {type: ComponentTypes.UNIT_AUDIT_LOG, reducer: auditLogReducer},
    {type: ComponentTypes.FLEET_AUDIT_LOG, reducer: auditLogReducer},

    // common
    {type: ComponentTypes.DATA_EXPLORATION_CHART, reducer: dataExplorationChartReducer},
    {type: ComponentTypes.SENSOR_SELECTOR, reducer: sensorSelectorReducer},
    {type: ComponentTypes.ICON_PICKER, reducer: iconPickerReducer},

    // Notifications
    {type: ComponentTypes.SUBSCRIPTION_DIALOG, reducer: subscriptionDialogReducer},
    {type: ComponentTypes.PROVIDER_DIALOG, reducer: providerDialogReducer},
    {type: ComponentTypes.DEVICE_DIALOG, reducer: deviceDialogReducer},
    {type: ComponentTypes.SUBSCRIPTIONS_DISPLAY, reducer: subscriptionsDisplayReducer}
]);

const initialState = {
    app: appInitialState,
};

const routerReducer = connectRouter(history);

const rootReducer = (state = initialState, action) => {

    if (action.type === rootActionTypes.ROOT_CLEAR_OWNER_STATE) {
        return onClearOwnerState(state, action);
    }

    if (action.type === rootActionTypes.ROOT_REMOVE_STATE) {
        return onRemoveState(state, action);
    }

    // We always pass an action to the router reducer since we have no way to
    // distinguish the actions from this library
    let updatedState = {...state, router: routerReducer(state.router, action)};

    if (!_.isNil(action.stateDef)) {
        const {key} = action.stateDef;
        return {...updatedState, [key]: reducerMap(updatedState[key], action)};
    } else {
        let newState = {app: appReducer(updatedState.app, action)};
        return {...updatedState, ...newState};
    }
};

const onRemoveState = (state, action) => {
    // Remove the state properties identified by the keys collection from the state.
    return _.isEmpty(action.keys) ? state : _.omit(state, action.keys);
};

const onClearOwnerState = (state, action) => {
    // Clearing out the owner state removes all state objects except the app and router states.
    return _.pick(state, ['app', 'router']);
};

export default rootReducer;
