import _ from 'lodash';
import moment from 'moment';
import reduceReducers from "reduce-reducers";

import { getUniqueSeriesColor } from '../../../../common/dataExplorationChart/services/dataExplorationChartService';
import { X_AXIS_IDS } from '../../../../../components/controls/charts/cardChart/cardChart';

import appActionTypes from '../../../../app/appActionTypes';

import exportCsvReducer from '../../../../common/exportCsv/exportCsvReducer';

import { liveViewState, getRuleTemplate } from './liveViewSelectors';
import liveViewActionTypes from './liveViewActionTypes';
import * as liveViewService from './services/liveViewService';

const initialState = liveViewState();

const liveViewReducer = (state = initialState, action) => {
  const reducer = reduceReducers(
    viewReducer,
    exportCsvReducer
  );

  return reducer(state, action);
};

const viewReducer = (state = initialState, action) => {

  switch(action.type) {

    case liveViewActionTypes.LIVE_VIEW_QUERY_TRUCKS_STARTING:
      return { ...state, queryRunning: true };
    case liveViewActionTypes.LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_STARTING:
      return { ...state, chartQueryRunning: true };
    case liveViewActionTypes.LIVE_VIEW_QUERY_TRUCKS_SUCCESS:
      return onQueryTrucksSuccess(state, action);
    case liveViewActionTypes.LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_SUCCESS:
      return onQuerySensorForTrucksSuccess(state, action);
    case liveViewActionTypes.LIVE_VIEW_QUERY_TRUCKS_ERROR:
      return { ...state, queryRunning: false };
    case liveViewActionTypes.LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_ERROR:
      return { ...state, chartQueryRunning: false };

    case liveViewActionTypes.LIVE_VIEW_SET_TIME_FRAME:
      return onSetTimeFrame(state, action);
    case liveViewActionTypes.LIVE_VIEW_SET_UNIT_TYPE:
      return onSetUnitType(state, action);
    case liveViewActionTypes.LIVE_VIEW_SET_TRUCK_FILTER:
      return onSetTruckFilter(state, action);

    case liveViewActionTypes.LIVE_VIEW_SET_SENSOR_SELECTOR_OPEN:
      return onSetSensorSelectorOpen(state, action);

    case liveViewActionTypes.LIVE_VIEW_SET_SELECTED_SENSORS:
      return onSetSelectedSensors(state, action);
    case liveViewActionTypes.LIVE_VIEW_DEFINITION_SET_SENSOR:
      return onSetSensorForDefinition(state, action);
    
    case liveViewActionTypes.LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY:
      return onToggleContextVisibility(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_ALL_OTHERS:
      return onToggleContextVisibilityAllOthers(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_OTHER_AFTER:
      return onToggleContextVisibilityOtherAfter(state, action);

    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_CHART:
      return onConfigureChart(state, action);

    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_SET_CONTEXT_COLOR:
      return onSetContextColor(state, action);

    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_CHART_SELECT_TAB:
      return onSelectConfigTab(state, action);

    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_ADD_DATA_RULE:
      return onAddDataRule(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_DATA_RULE_SET_COLOR_PICKER_STATE:
      return onDataRuleSetColorPickerState(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_REMOVE_DATA_RULE:
      return onRemoveDataRule(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_MOVE_SENSOR:
      return onMoveSensor(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_SET_SENSOR_DISPLAY_NAME:
      return onSetSensorDisplayName(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_SET_SENSOR_UOM:
      return onSetSensorUOM(state, action);
    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_DATA_RULE_SET_PROPERTY:
      return onDataRuleSetProperty(state, action);

    case liveViewActionTypes.LIVE_VIEW_SET_FULLSCREEN_OPTION:
      return onSetFullScreenOption(state, action);

    case liveViewActionTypes.LIVE_VIEW_HIGHLIGHT_TRUCK:
      return onHighlightTruck(state, action);

    case liveViewActionTypes.LIVE_VIEW_XAXIS_VISIBLE_RANGE_CHANGED:
      return onXAxisVisibleRangeChanged(state, action);
    case liveViewActionTypes.LIVE_VIEW_YAXIS_VISIBLE_RANGE_CHANGED:
      return onYAxisVisibleRangeChanged(state, action);

    case appActionTypes.APP_PROCESS_WEBSOCKET_DATA:
      return onProcessWebSocketData(state, action);

    case liveViewActionTypes.LIVE_VIEW_PAUSE_SUBSCRIPTION:
      return onPauseSubscription(state, action);
    case liveViewActionTypes.LIVE_VIEW_SET_DATA_DISPLAY_MODE_OPTION:
      return onSetDataDisplayModeOption(state, action);

    case liveViewActionTypes.LIVE_VIEW_RESET:
      return onReset(state, action);

    case liveViewActionTypes.LIVE_VIEW_USER_CONFIGURATION_SAVE_STARTING:
      return { ...state, userConfigurationSaving: true };
    // We don't really do anything special from the save return
    case liveViewActionTypes.LIVE_VIEW_USER_CONFIGURATION_SAVE_SUCCESS:
    case liveViewActionTypes.LIVE_VIEW_USER_CONFIGURATION_SAVE_ERROR:  
      return { ...state, userConfigurationSaving: false };

    case liveViewActionTypes.LIVE_VIEW_ON_ROLLOVER:
      return onRollover(state, action);

    case liveViewActionTypes.LIVE_VIEW_CONFIGURE_CLOSE_GAPS:
      return onCloseGaps(state, action);

    case liveViewActionTypes.LIVE_VIEW_TOGGLE_LEGEND:
      return onToggleLegend(state, action);

    case liveViewActionTypes.LIVE_VIEW_DEFINITION_SET_FLEET_NAME:
      return onSetFleetNameForDefinition(state, action);

    default:
      return state;
  }

}

const onQueryTrucksSuccess = (state, action) => {
  const unitType = state.selectedUnitType.value;

  let averageTruck = {
    truckPid: 0,
    truckName: 'Average',
    unitType: unitType,
    slotNumber: 0,
    labels: ['lowSide', 'highSide', 'clean', 'dirty'] // 'clean' and 'dirty' are only for frac pumps
  }

  let trucksWithAverage = _.cloneDeep(action.queryResults.trucksForLiveView);
  trucksWithAverage.push(averageTruck);

  const selectedTruckFilter = state.selectedTruckFilter;

  let processedTrucks = liveViewService.processTrucksForLiveView(trucksWithAverage, selectedTruckFilter, state.definition);
  let newDefinition = liveViewService.updateDefinitionWithTrucks(state.definition, processedTrucks);

  let newState = {
    ...state,
    queryRunning: false,
    trucks: trucksWithAverage,
    selectedTrucks: processedTrucks,
    definition: newDefinition,
    primaryXValues: [],
    primaryYValues: [],
    relativeYValues: [],
    shouldRefreshChart: moment()
  }

  if(!_.isEmpty(state.selectedSensors)){
    const data = liveViewService.generateInitialDataSet(newState.selectedTrucks, state.selectedSensors);
    newState.data = data;
  } else {
    newState.data = []; // force reset of data if no selected sensors
  }

  return newState;
}

const onSetTimeFrame = (state, action) => {
  let startTime = moment().subtract(action.timeFrame.value, 'minutes').startOf('minute').unix();
  let endTime = moment().startOf('minute').unix()

  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.timeRange.startTime = startTime;
  newDefinition.primary.timeRange.endTime = endTime;
  newDefinition.primary.annotations = [];

  newDefinition.secondary.timeRange.startTime = startTime;
  newDefinition.secondary.timeRange.endTime = endTime;
  newDefinition.secondary.annotations = [];

  // Reset trucks, sensors, and data since the time frame is critical to having the right values for those
  let newState = {
    ...state,
    selectedTimeFrame: action.timeFrame,
    definition: newDefinition
  }
  newState.primaryXValues = [];
  newState.primaryYValues = [];
  newState.relativeYValues = [];
  newState.shouldRefreshChart = moment();

  return newState;
}

const onSetUnitType = (state, action) => {
  let newState = _.cloneDeep(state);
  if(!_.isNil(action.unitTypeConfigs)) newState.unitTypeConfigs = action.unitTypeConfigs;

  newState.selectedUnitType = action.unitType;
  newState.selectedSensors = newState.unitTypeConfigs[action.unitType.value].selectedSensors;
  newState.selectedSensor = newState.unitTypeConfigs[action.unitType.value].selectedSensor;
  newState.selectedTruckFilter = newState.unitTypeConfigs[action.unitType.value].selectedTruckFilter;
  // force reset of data when switching unit types
  // later on when it queryTrucks to get a new list of trucks for the unit type, it will generate the data for selected sensors
  newState.data = [];

  return newState;
}

const onSetTruckFilter = (state, action) => {
  let processedTrucks = liveViewService.processTrucksForLiveView(state.trucks, action.truckFilter, state.definition);
  let newDefinition = liveViewService.updateDefinitionWithTrucks(state.definition, processedTrucks);

  let newState = {
    ...state,
    selectedTruckFilter: action.truckFilter,
    selectedTrucks: processedTrucks,
    definition: newDefinition
  }
  // update corresponding unitType configs
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedTruckFilter`, action.truckFilter);

  return newState;

}

const onSetSensorSelectorOpen = (state, action) => {
  return {
    ...state,
    sensorSelectorOpen: action.shouldOpen
  }
}

const onSetSelectedSensors = (state, action) => {

  const newState = _.cloneDeep(state);
  // processSensors would return empty array if no selected sensors
  const processedSensors = liveViewService.processSensors(state.selectedSensors, action.selectedSensors);
  newState.selectedSensors = processedSensors;
  // update corresponding unitType configs
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, processedSensors);

  if (!_.isEmpty(action.selectedSensors)) {
    const data = liveViewService.generateInitialDataSet(state.selectedTrucks, processedSensors);
    newState.data = data;

    // If we removed the currently selected sensor from the list of sensors, clear the selected sensor property
    // and any annotations that were associated with it
    if (!_.isNil(state.selectedSensor)) {
      const foundSensor = _.find(processedSensors, ['sensorSetId', newState.selectedSensor.sensorSetId]);
      if (_.isNil(foundSensor)) {
        // Clear out the annotations - we no longer have a selected sensor and the definition only holds the
        // annotations for the currently selected sensor
        // Clear out the data as well since we no longer have a selected sensor
        resetSensorState(newState);
      }
    }
  } else {
    newState.data = [];
    // clear out all sensor related state
    resetSensorState(newState);
  }
  return newState;
}

/**
 * Reset the state related to the selected sensor
 */
const resetSensorState = (state) => {
  state.definition.primary.annotations = [];
  state.definition.secondary.annotations = [];
  state.selectedSensor = null;
  state.primaryXValues = [];
  state.primaryYValues = [];
  state.relativeYValues = [];
  state.shouldRefreshChart = moment();
  // update corresponding unitType configs
  _.set(state, `unitTypeConfigs.${state.selectedUnitType.value}.selectedSensor`, null);
}

const onSetSensorForDefinition = (state, action) => {
  let newState = _.cloneDeep(state);

  if (!_.isNil(action.sensor)) {
    let newDefinition = liveViewService.updateDefinitionWithSensor(newState.definition, action.sensor);
    const axisTitle = liveViewService.generateAxisTitle(action.sensor, action.fleetName)
    newDefinition.primary.axisTitle = axisTitle;
    newDefinition.secondary.axisTitle = axisTitle;
    newDefinition.primary.annotations = [];
    newDefinition.secondary.annotations = [];
    newState.selectedSensor = action.sensor;
    _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensor`, action.sensor);

    const existingRulesForSensorSetId = newState.selectedSensor.conditionalFormatting.rules;
    if (!_.isEmpty(existingRulesForSensorSetId) && (newState.selectedSensor.conditionalFormatting.applied === true)) {
      _.forEach(existingRulesForSensorSetId, (rule) => {
        liveViewService.addAnnotationForRule(newDefinition, rule);
      });
      newState.shouldRefreshChart = moment();
    }
    newState.definition = newDefinition;
  } else {
    newState.selectedSensor = null;
    _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensor`, null);
  }
  return newState;
}

const onQuerySensorForTrucksSuccess = (state, action) => {  
  // Create the expected array of xValues for the chart
  // CLAIM: Not all the trucks will have values for all xValues - this is expected
  // Note the _.range function is inclusive of the start and exclusive of the end 
  const xValues = _.range(action.startTime, action.endTime)

  let yValues = {};
  // This is the Average truck
  yValues[0] = new Array(xValues.length).fill(NaN);
  
  let relativeYValues = {};
  relativeYValues[0] = new Array(xValues.length).fill(NaN);
  let truckDataPointsHash = {};

  _.forEach(xValues, (xValue, index) => {

    // Number of trucks that contributed to the average value at this timestamp
    // This is not always the same as the number of selected trucks
    let numTrucksForAverageValue = 0; 

    _.forEach(action.queryResults.chartSeries, (truck) => {
      // In our first pass of the xValues, we will create a hash of each trucks data points so we can easily (and quickly) 
      // access them when we are iterating through the xValues
      // Also initialize the relative values and yValues for each truck
      if (index === 0) {
        // Initialize the relative values of this truck
        relativeYValues[truck.truckPid] = new Array(xValues.length).fill(NaN);
        yValues[truck.truckPid] = new Array(xValues.length).fill(NaN);
        truckDataPointsHash[truck.truckPid] = {};
        _.forEach(truck.timestamps, (timestamp, timestampIndex) => {
          truckDataPointsHash[truck.truckPid][timestamp] = truck.values[timestampIndex];
        });
      }

      const foundDataPoint = truckDataPointsHash[truck.truckPid][xValue];
      if (!_.isNil(foundDataPoint)) {
        yValues[truck.truckPid][index] = foundDataPoint;
        // Increment the running sum for the average truck
        if (!isNaN(foundDataPoint)) {
          yValues[0][index] = (isNaN(yValues[0][index]) ? 0 : yValues[0][index]) + foundDataPoint;
          numTrucksForAverageValue++;
        }
      }
    });

    // Now calculate the average value for this timestamp
    yValues[0][index] = Number((yValues[0][index] / numTrucksForAverageValue).toFixed(2));

    relativeYValues[0][index] = 0; // Average relative to itself is always 0
    // Now do relative values for each truck
    _.forEach(action.queryResults.chartSeries, (truck) => {
      const truckValue = yValues[truck.truckPid][index];
      if (!isNaN(truckValue)) {
        const relativeValue = isNaN(yValues[0][index]) ? NaN : Number((truckValue - yValues[0][index]).toFixed(2));
        relativeYValues[truck.truckPid][index] = relativeValue;    
      } 
    });
    
  });

  return {
    ...state,
    chartQueryRunning: false,
    primaryXValues: xValues,
    primaryYValues: yValues,
    relativeYValues: relativeYValues
  }

};

const onToggleContextVisibility = (state, action) => {
  const newDefinition = liveViewService.toggleContextVisibility(state.definition, action.truckPid, state.dataDisplayModeToggle, state.dataDisplayModes);
  return {
    ...state,
    definition: newDefinition,
    shouldRefreshChart: moment()
  }
}

const onToggleContextVisibilityAllOthers = (state, action) => {
  const newDefinition = liveViewService.toggleContextVisibilityAllOthers(state.definition, action.truckPid, action.isVisible, state.dataDisplayModeToggle, state.dataDisplayModes);
  return {
    ...state,
    definition: newDefinition,
    shouldRefreshChart: moment()
  }
}

const onToggleContextVisibilityOtherAfter = (state, action) => {
  const newDefinition = liveViewService.toggleContextVisibilityOtherAfter(state.definition, action.truckPid, action.isVisible, state.dataDisplayModeToggle, state.dataDisplayModes);
  return {
    ...state,
    definition: newDefinition,
    shouldRefreshChart: moment()
  }
}

const onConfigureChart = (state, action) => {
  return {
    ...state,
    configureChart: action.configureChart
  }              
}

const onSetContextColor = (state, action) => {   
  
  const newSelectedTrucks = _.cloneDeep(state.selectedTrucks);

  // CLAIM: A Selected Truck with the passed in truckPid will always exist in the selectedTrucks array
  const foundTruck = _.find(newSelectedTrucks, ['truckPid', action.truckPid]);
  foundTruck.color = action.color;

  const newDefinition = liveViewService.setContextColor(state.definition, action.truckPid, action.color);

  return {
    ...state,
    selectedTrucks: newSelectedTrucks,
    definition: newDefinition,
    shouldRefreshChart: moment()
  }
}

const onSelectConfigTab = (state, action) => {
  return {                                                                  
    ...state,
    configTabIndex: action.tabIndex
  }
}

/**
 * This Adds a Rule to a sensor's conditional formatting collection.
 * This does not automatically add the annotation to the chart definition because we can add rules without having 
 * the conditional formatting applied. 
 * When the conditional formatting is applied, we will add the annotations to the chart definition
 */
const onAddDataRule = (state, action) => {
  const selectedSensors = _.cloneDeep(state.selectedSensors);
  const currentSensor = selectedSensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId; });
  let newRule = getRuleTemplate();
  newRule.id = currentSensor.conditionalFormatting.rules.length + 1;
  newRule.color = getUniqueSeriesColor(_.isEmpty(currentSensor.conditionalFormatting.rules) ? [] : currentSensor.conditionalFormatting.rules.map(rule => rule.color));
  newRule.condition = state.configRuleConditions[0];
  currentSensor.conditionalFormatting.rules.push(newRule);

  let newState =  {
    ...state,
    selectedSensors: selectedSensors
  };
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, selectedSensors);

  return newState;
}

const onDataRuleSetColorPickerState = (state, action) => {

  const newColorPickerStates = [];
  if (!_.isNil(action.sensorSetId)) {
    const colorPickerState = newColorPickerStates[action.index];
    if (_.isNil(colorPickerState)){
      newColorPickerStates[action.index] = { sensor: action.sensorSetId, origColor: action.origColor };
    } else {
      colorPickerState.origColor = action.origColor;
    }
  }
  return {
    ...state,
    ruleColorPickerStates: newColorPickerStates
  };
}

/**
 * This Removes a Rule to a sensor's conditional formatting collection.
 * IF we are removing a Rule for the currently selected sensor, then we need to also remove the annotation from the chart definition 
 */
const onRemoveDataRule = (state, action) => {
  const selectedSensors = _.cloneDeep(state.selectedSensors);
  const currentSensor = selectedSensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId; });
  const ruleId = currentSensor.conditionalFormatting.rules[action.ruleIndex].id;
  currentSensor.conditionalFormatting.rules.splice(action.ruleIndex, 1);
  if (_.isEmpty(currentSensor.conditionalFormatting.rules)) {
    currentSensor.conditionalFormatting.applied = false;
  }

  let newState = {
    ...state,
    selectedSensors: selectedSensors
  };
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, selectedSensors);

  // If we have removed a rule for the existing sensor, then update the definition so it does not have the related annotation anymore
  if (!_.isNil(state.selectedSensor) && (action.sensorSetId === state.selectedSensor.sensorSetId)) {
    _.remove(newState.definition.primary.annotations, (annotation) => annotation.id === ruleId);
    _.remove(newState.definition.secondary.annotations, (annotation) => annotation.id === ruleId);
    newState.shouldRefreshChart = moment();
  }

  return newState;
}

const onMoveSensor = (state, action) => {
  const newState = _.cloneDeep(state);
  const [movedSensor] = newState.selectedSensors.splice(action.removedIndex, 1);
  newState.selectedSensors.splice(action.addedIndex, 0, movedSensor);
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, newState.selectedSensors);

  const [movedSensorData] = newState.data.splice(action.removedIndex+1, 1);
  newState.data.splice(action.addedIndex+1, 0, movedSensorData);

  return newState;
}

const onSetSensorDisplayName = (state, action) => {
  const newState = _.cloneDeep(state);

  const sensor = _.find(newState.selectedSensors, { sensorSetId: action.sensorSetId });
  sensor.displayName = action.displayName;
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, newState.selectedSensors);

  const titleParts = newState.definition.primary.axisTitle.split(' - ');
  const axisTitle = titleParts[0] + ' - ' + action.displayName + ' (' + sensor.uom + ')';
  newState.definition.primary.axisTitle = axisTitle;
  newState.definition.secondary.axisTitle = axisTitle;

  return newState;
}

/**
 * This handles the action of turning on/off the conditional formatting for a sensor
 * By turning on the conditional formatting, we need to add the annotations to the chart definition
 * By turning off the conditional formatting, we need to remove all the annotations from the chart definition
 */
const onDataRuleSetProperty = (state, action) => {
  let newShouldRefreshChart = {...state.shouldRefreshChart}
  const newDefinition = _.cloneDeep(state.definition);
  const selectedSensors = _.cloneDeep(state.selectedSensors);
  const currentSensor = selectedSensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId; });
  let shouldUpdateAnnotations = false;

  if(_.isNil(action.index)){
    currentSensor.conditionalFormatting[action.property] = action.value;
    if(action.property === 'applied' && action.value === true) {
      if (_.isEmpty(currentSensor.conditionalFormatting.rules)) {
        // Add default rule when there're no rules in the conditionalFormatting
        let newRule = getRuleTemplate();
        newRule.id = 1;
        newRule.color = getUniqueSeriesColor(_.isEmpty(currentSensor.conditionalFormatting.rules) ? [] : currentSensor.conditionalFormatting.rules.map(rule => rule.color));
        newRule.condition = state.configRuleConditions[0];

        currentSensor.conditionalFormatting.rules.push(newRule);
      } else {
        shouldUpdateAnnotations = true;
      }
    } else if(action.property === 'applied' && action.value === false) {
      // Remove all annotations when the conditional formatting is turned off
      newDefinition.primary.annotations = [];
      newDefinition.secondary.annotations = [];
      newShouldRefreshChart = moment();
    }
  } else {
    currentSensor.conditionalFormatting.rules[action.index][action.property] = action.value;
    shouldUpdateAnnotations = true;
  }

  if (action.property === "condition" && !_.includes(action.value, "between")) {
    currentSensor.conditionalFormatting.rules[action.index].value2 = '';
    shouldUpdateAnnotations = true;
  }

  let newState = {
    ...state,
    selectedSensors: selectedSensors,
    shouldRefreshChart: newShouldRefreshChart,
    definition: newDefinition
  }
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, selectedSensors);

  // If we are working with rules for the currently selected sensor, make sure the annotations are updated as well
  if (!_.isNil(newState.selectedSensor) && (action.sensorSetId === newState.selectedSensor.sensorSetId) && (shouldUpdateAnnotations === true) && (currentSensor.conditionalFormatting.applied === true)) {
    if (!_.isNil(action.index)) {
      liveViewService.updateDefinitionWithAnnotation(newState, currentSensor.conditionalFormatting.rules[action.index]);
    } else {
      _.forEach(currentSensor.conditionalFormatting.rules, (rule) => {
        liveViewService.updateDefinitionWithAnnotation(newState, rule);
      });
    }
  }

  return newState;
}

const onSetSensorUOM = (state, action) => {
  const newSensors = _.cloneDeep(state.selectedSensors);
  _.find(newSensors, function (sensor) { return sensor.sensorSetId === action.sensorSetId; }).uom = action.uom;

  let newState = {
    ...state,
    selectedSensors: newSensors,
  };
  _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensors`, newSensors);

  // If we are updating the currently selected sensor, update that as well
  if (!_.isNil(newState.selectedSensor) && (action.sensorSetId === newState.selectedSensor.sensorSetId)) {
    newState.selectedSensor.uom = action.uom;
    _.set(newState, `unitTypeConfigs.${newState.selectedUnitType.value}.selectedSensor`, newState.selectedSensor);
  }

  return newState;
}

const onSetFullScreenOption = (state, action) => {
  return {
    ...state,
    fullScreenToggle: action.option
  }
}

const onHighlightTruck = (state, action) => {
  const newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.highlightContext = action.truckPid;
  newDefinition.secondary.highlightContext = action.truckPid;

  return {
    ...state,
    definition: newDefinition,
    shouldRefreshChart: moment()
  }
}

const onXAxisVisibleRangeChanged = (state, action) => {
  const newRanges = _.cloneDeep(state.ranges);
  newRanges.x[action.xAxisId] = { min: action.xMin, max: action.xMax };

  return {
    ...state,
    ranges: newRanges
  }
}

const onYAxisVisibleRangeChanged = (state, action) => {
  const newRanges = _.cloneDeep(state.ranges);
  newRanges.y[action.yAxisId] = { min: action.yMin, max: action.yMax };

  return {
    ...state,
    ranges: newRanges
  }
}

/**
 * NOTE: action.data.payload contains data for only **one** truck and all requested sensors.
 * 
 * Updates the data object that holds the data for the live data grid.
 * If the user has selected a sensor, also updates the chart data. This is a two part process:
 *  1. Update our yValues and xValues (what happens in here)
 *    a. Since data comes in 1 truck at a time, we have to keep in mind of the timestamp we receive
 *      - if the timestamp is new, then we add it to the end of the xValues array and remove the first element
 *        - this subsequent moves the "high watermark" for all trucks forward
 *      - if the timestamp is old, then we update the value at the appropriate index
 *  2. 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const onProcessWebSocketData = (state, action) => {
  const data = _.cloneDeep(state.data);
  const newXValues = _.cloneDeep(state.primaryXValues);
  const newYValues = _.cloneDeep(state.primaryYValues);
  const newRelativeValues = _.cloneDeep(state.relativeYValues);
  const newLastProcessedTruckTimestamps = {...state.lastProcessedTruckTimestamps};

  // Each row will have a column for sensor and a column for each truck
  const dataRowTemplate = {}
  state.selectedTrucks.forEach(truck => {
    dataRowTemplate[truck.truckPid] = null;
  });

  // The query results are for a truck and all sensors 
  const truckData = action.data.payload[0];

  _.forEach(truckData.sensorSetIds, (sensorSetId, index) => {
    // Get the row for this sensor
    let dataRow = _.find(data, ['sensorSetId', sensorSetId]);
    if (!_.isNil(dataRow)) {
      const truckValue = Number(truckData.values[index].toFixed(2));

      // NOTE: We could get data from the past if the datamonitor is backed up
      // but we show it anyways since this is the latest value we have
      dataRow[truckData.truckPid] = truckValue;

      // For the currently selected sensor, update the chart data
      if (!_.isNil(state.selectedSensor) && sensorSetId === state.selectedSensor.sensorSetId) {

        const foundTimeStampIndex = _.sortedIndexOf(newXValues, truckData.ts);

        // This is a new timestamp
        if (foundTimeStampIndex === -1) {

          if (!_.isEmpty(newXValues) && (truckData.ts > newXValues[newXValues.length - 1])) {
            newXValues.push(truckData.ts);
            newXValues.splice(0,1);
          }

          if (!_.isEmpty(newYValues) && !_.isNil(newYValues[truckData.truckPid])) {
            newYValues[truckData.truckPid].push(truckValue);
            newYValues[truckData.truckPid].splice(0,1);
            newLastProcessedTruckTimestamps[truckData.truckPid] = truckData.ts;
          }

          if (newXValues.length > 0 && !_.isEmpty(newYValues)) {
            // Update the average
            const newAverageValue = liveViewService.calculateAverageAtTimeIndex(state.selectedTrucks, newYValues, (newXValues.length - 1));
            newYValues[0].push(newAverageValue);
            newYValues[0].splice(0,1);

            // Update the relative values
            newRelativeValues[0].push(0); // Average relative to itself is always 0
            newRelativeValues[0].splice(0,1);
            liveViewService.calculateAndUpdateRelativeValuesAtTimeIndex(state.selectedTrucks, newYValues, newRelativeValues, (newXValues.length - 1), newAverageValue, false);
          }
        } else {

          const lastProcessedTimestampForTruck = state.lastProcessedTruckTimestamps[truckData.truckPid];

          if ((foundTimeStampIndex === newXValues.length - 1) && (!_.isNil(lastProcessedTimestampForTruck) && truckData.ts > lastProcessedTimestampForTruck)) {
            newYValues[truckData.truckPid].push(truckValue);
            newYValues[truckData.truckPid].splice(0,1);
            newLastProcessedTruckTimestamps[truckData.truckPid] = truckData.ts;
          } else {
            newYValues[truckData.truckPid][foundTimeStampIndex] = truckValue;
          }

          if (newXValues.length > 0 && !_.isEmpty(newYValues)) {
            // Update the average
            const newAverageValue = liveViewService.calculateAverageAtTimeIndex(state.selectedTrucks, newYValues, foundTimeStampIndex);
            newYValues[0][foundTimeStampIndex] = newAverageValue;

            // Update the relative values
            liveViewService.calculateAndUpdateRelativeValuesAtTimeIndex(state.selectedTrucks, newYValues, newRelativeValues, foundTimeStampIndex, newAverageValue, true);
          }
        }
      }
    }
  });

  const selectedTruckPids = _.map(state.selectedTrucks, 'truckPid');

  _.forEach(data, (row, index) => {
    if (index > 0) {
      let averageValue = 0;
      let numOfTrucksContributing = 0;
      // Get all property keys except the '0' key, which is the average truck
      (_.filter(_.keys(row), (key) => {
        return key !== '0' &&
            key !== 'sensorSetId' &&
            _.includes(selectedTruckPids, Number(key))
      })).forEach(key => {
        if (!_.isNil(row[key]) && !isNaN(row[key])) {
          averageValue += row[key];
          numOfTrucksContributing++;
        }
      });
      row['0'] = Number((averageValue / numOfTrucksContributing).toFixed(2));
    }
  })

  return {
    ...state,
    data: data,
    primaryXValues: newXValues,
    primaryYValues: newYValues,
    relativeYValues: newRelativeValues,
    lastProcessedTruckTimestamps: newLastProcessedTruckTimestamps
  }
}

const onPauseSubscription = (state, action) => {
  return {
    ...state,
    pauseLiveFeed: action.isPaused
  }
}

const onSetDataDisplayModeOption = (state, action) => {

  const newDefinition = _.cloneDeep(state.definition);

  // Absolute
  if (action.option === state.dataDisplayModes[0]) {
    // Show primary data
    _.forEach(newDefinition.primary.contexts, (context) => {
      // Maintain the visibility of the context when switching between data display modes
      context.visible = _.find(state.definition.secondary.contexts, ['id', context.id]).visible;
    });
    // Hide all the secondary data
    _.forEach(newDefinition.secondary.contexts, (context) => {
      context.visible = false;
    });

    newDefinition.primary.isVisible = true;
    newDefinition.secondary.isVisible = false;

  } else {
    // Relative
    // Hide all the primary data
    _.forEach(newDefinition.primary.contexts, (context) => {
      context.visible = false;
    });
    // Show secondary data
    _.forEach(newDefinition.secondary.contexts, (context) => {
      // Maintain the visibility of the context when switching between data display modes
      context.visible = _.find(state.definition.primary.contexts, ['id', context.id]).visible;
    });
    
    newDefinition.primary.isVisible = false;
    newDefinition.secondary.isVisible = true;
  }

  return {
    ...state,
    definition: newDefinition,
    dataDisplayModeToggle: action.option,
    shouldRefreshChart: moment()
  }
}

/**
 * Resets data & chart state to the initial state
 */
const onReset = (state, action) => {
  const newState = {
    ...state,
  }
  newState.primaryXValues = [];
  newState.primaryYValues = [];
  newState.relativeYValues = [];
  newState.shouldRefreshChart = moment();

  newState.data = [];

  return newState;
}

const onRollover = (state, action) => {
  const newRolloverTimestamp = _.isNil(action.xValue) ? null : moment.unix(action.xValue).format('lll');
  
  const newDefinition = _.cloneDeep(state.definition);
  switch(action.xAxis) {
    case X_AXIS_IDS[0]: {
      const contextToUpdate = newDefinition.primary.contexts.find((context) => context.id === action.contextId && context.visible === true);
      if (!_.isNil(contextToUpdate)) {
        contextToUpdate.value = Number(action.yValue).toFixed(2);
      }
      break;
    }
    case X_AXIS_IDS[1]: {
      const contextToUpdate = newDefinition.secondary.contexts.find((context) => context.id === action.contextId && context.visible === true);
      if (!_.isNil(contextToUpdate)) {
        contextToUpdate.value = Number(action.yValue).toFixed(2);
      }
      break;
    }
    default: {
      _.map(newDefinition.primary.contexts, (context) => {
        context.value = '-';
      });
      _.map(newDefinition.secondary.contexts, (context) => {
        context.value = '-';
      });
      break;
    }
  }


  return {
    ...state,
    rollOverTimestamp: newRolloverTimestamp,
    definition: newDefinition,
  }
}

const onCloseGaps = (state, action) => {
  const newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.closeGaps = action.closeGaps;
  newDefinition.secondary.closeGaps = action.closeGaps;

  return {
    ...state,
    definition: newDefinition,
    shouldRefreshChart: moment()
  }
}

const onToggleLegend = (state, action) => {
  return {
    ...state,
    showLegend: !state.showLegend
  }
}

const onSetFleetNameForDefinition = (state, action) => {
  const newDefinition = _.cloneDeep(state.definition);

  const axisTitle = liveViewService.generateAxisTitle(state.selectedSensor, action.fleetName)
  newDefinition.primary.axisTitle = axisTitle;
  newDefinition.secondary.axisTitle = axisTitle;

  return {
    ...state,
    definition: newDefinition
  }
}

export default liveViewReducer;