import React, {Component} from 'react';
import PropTypes from "prop-types";

import { Typography, Paper, Box } from '@mui/material';

import * as dataExplorerService from './dataExplorerService';

class DataExplorerTicks extends Component {

  constructor(props) {
    super(props);
    // Styles set with constructor since SPECS values get recalculated depending on the page visited by user.
    // As such, this allows SPECS to be updated within this component to retrieve the correct rendering SPECS
    // for the page's use of the dataExplorer (parent) component.
    this.styles = {
      container: {
        position: 'relative',
        width: dataExplorerService.SPECS.CHART_WIDTH,
        height: dataExplorerService.SPECS.TICK_CONTAINER_HEIGHT,
        borderRadius: 0,
      },
      tick: {
        position: 'absolute',
        width: '1px',
        height: dataExplorerService.SPECS.TICK_HEIGHT,
        backgroundColor: 'grey.500',
      },
      label: {
        position: 'absolute',
      },
    };
  }

  render() {

    let ticks = dataExplorerService.getTicksToRender(this.props.selectedDateTime, this.props.selectedDuration);

    return (
      <Paper sx={this.styles.container}>
        {
          ticks.map((tick, index) => {
            return (
              <div key={index}>
                <Box sx={{...this.styles.tick, top: '0px', left: tick.left}} />
                {
                  !tick.isFirst &&
                  !tick.isLast &&
                  <Typography variant={'caption'} sx={{...this.styles.label, top: '8px', left: tick.left - 37}}>{tick.label}</Typography>
                }
                {
                  tick.isFirst &&
                  !tick.isLast &&
                  <Typography variant={'caption'} sx={{...this.styles.label, top: '8px', left: tick.left}}>{tick.label}</Typography>
                }
                {
                  !tick.isFirst &&
                  tick.isLast &&
                  <Typography variant={'caption'} sx={{...this.styles.label, top: '8px', left: tick.left - 80}}>{tick.label}</Typography>
                }
              </div>
            )
          })
        }
      </Paper>
    )
  }

}

DataExplorerTicks.propTypes = {
  selectedDateTime: PropTypes.object,
  selectedDuration: PropTypes.object,
};

export default DataExplorerTicks;
