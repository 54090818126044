import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import ComponentTypes from '../components/componentTypes';

import HomePage from '../components/app/support/homePage';
import AuthPage from '../components/app/support/authPage';
import AuthCallbackPage from '../components/app/support/authCallbackPage';
import AuthErrorPage from '../components/app/support/authErrorPage';
import LoadingPage from '../components/app/support/loadingPage';
import LoadingUserRoutesPage from '../components/app/support/loadingUserRoutesPage';
import LoadingErrorPage from '../components/app/support/loadingErrorPage';
import NotFoundPage from '../components/app/support/notFoundPage';
import NotSupportedPage from '../components/app/support/notSupportedPage';
import ExportPage from '../components/displays/export/exportPage';
import UserManagementPage from '../components/displays/userManagement/userMgmtPage';
import FleetMgmtPage from '../components/displays/fleetManagement/fleetMgmtDisplay';
import EquipmentListPage from '../components/displays/equipmentList/equipmentList';
import PumpDashboard from '../components/displays/pumpDashboard/pumpDashboard';
import DfpDashboard from '../components/displays/dfpDashboard/dfpDashboard';
import ClientStatus from '../components/displays/clientStatus/clientStatus';
import SystemSoftware from '../components/displays/systemSoftware/systemSoftware';
import ComponentsDisplay from '../components/displays/componentsDisplay/componentsDisplay';
import Settings from '../components/displays/settings/settings';
import FleetMap from '../components/displays/fleetMap/fleetMap';
import ComponentHours from '../components/displays/componentHours/componentHours';
import AlarmsDisplay from '../components/displays/alarms/alarmsDisplay';
import FleetOverview from '../components/displays/fleetOverview/fleetOverview';
import CoilDashboard from "../components/displays/coilDashboard/coilDashboard";
import DataExplorationDisplayV1 from "../components/displays/dataExplorationDisplay/dataExplorationDisplayV1";
import DataExplorationDisplay from "../components/displays/dataExplorationDisplay/dataExplorationDisplay";
import SubscriptionsDisplay from '../components/displays/subscriptions/subscriptionsDisplay';
import JobOverview from '../components/displays/jobOverview/jobOverview';
import CustomersDisplay from "../components/displays/customers/customersDisplay";
import DownloadsDisplay from "../components/displays/downloads/downloadsDisplay";
import OwnerManagementDisplay from '../components/displays/ownerManagement/ownerManagementDisplay';
import JobHistoryDisplay from '../components/displays/jobHistory/jobHistoryDisplay';
import WellsDisplay from "../components/displays/wells/wellsDisplay";
import AuditLogsDisplay from '../components/displays/auditLogs/auditLogsDisplay';
import AssetTrackingDisplay from '../components/displays/assetTracking/assetTrackingDisplay';
import RouteAssetManagementPage from '../components/displays/routeAssetManagement/routeAssetManagementPage';

/**
 * Returns the the router for the web app. Uses the users authentication state; whether
 * or not the user information has been loaded, and the user route configuration to
 * generate the route list for the router.
 *
 * This method is called on every render pass of application Layout component.
 */
const renderRouter = (supportedBrowser, isAuthenticated, userInfoLoaded, userRoutes, defaultRoute) => {
  let routes = [];
  if (!supportedBrowser) {
    routes = renderInvalidBrowserRouter();
  } else if (!isAuthenticated) {
    routes = renderNonAuthenticatedRouter();
  } else if (!userInfoLoaded) {
    routes = renderAuthenticatedNoUserRouter();
  } else if (_.isEmpty(userRoutes.routes)) {
    routes = renderAuthenticatedNoRoutesRouter();
  } else {
    routes = renderAuthenticatedWithRoutesRouter(userRoutes, defaultRoute);
  }
  return (<Switch>{routes}</Switch>);
};

/**
 * Returns the routes for the web app when being viewed in an unsupported browser
 */
const renderInvalidBrowserRouter = () => {
  let routes = [];
  // Add a route to the home page which just shows the browser compatibility messages
  routes.push(<Route path='/home' render={() => { return (<NotSupportedPage/>) }} key='/home'/>);
  // Default route, just redirect to the home page.
  routes.push(<Redirect to={'/home'}  key='default'/>);
  return routes;
};

/**
 * Returns the routes for the web app when the user is not authenticated.
 */
const renderNonAuthenticatedRouter = () => {
  let routes = [];
  // Add a route to handle the callback from Auth0 (this is called directly from Auth0)
  routes.push(<Route path='/callback' render={() => { return (<AuthCallbackPage/>) }} key='/callback'/>);
  // Add a route to handle an error from Auth0 (this is called internally while parsing Auth0 results)
  routes.push(<Route path='/error' render={() => { return (<AuthErrorPage/>) }} key='/error'/>);
  // Add a route to the home page.
  routes.push(<Route path='/home' render={() => { return (<AuthPage/>) }} key='/home'/>);
  // Default route, just redirect to the home page.
  routes.push(<Redirect to={'/home'}  key='default'/>);
  return routes;
};

/**
 * Returns the routes for the web app when the user is authenticated, but we have not loaded
 * the user information yet. Basically just display the loading page for any routes.
 */
const renderAuthenticatedNoUserRouter = () => {
  let routes = [];
  // Only one route which displays the loading page since we need to load the user information.
  routes.push(<Route path='/error' render={() => { return (<LoadingErrorPage/>) }} key='/error'/>);
  routes.push(<Route render={() => { return (<LoadingPage/>) }} key='default'/>);
  return routes;
};

/**
 * Returns the routes for the web app when the user is authenticated, but we do not yet have
 * the routes that are supported by the logged in user and owner.
 */
const renderAuthenticatedNoRoutesRouter = () => {
  let routes = [];
  // Only one route which displays the loading page since we need to load the route information for this user.
  routes.push(<Route path='/error' render={() => { return (<LoadingErrorPage/>) }} key='/error'/>);
  routes.push(<Route render={() => { return (<LoadingUserRoutesPage/>) }} key='default'/>);
  return routes;
};

/**
 * Returns the routes for the web app when the user is authenticated and we have loaded the
 * user information. This will be a combination of some standard routes (/home) and routes
 * the user is configured to use.
 */
const renderAuthenticatedWithRoutesRouter = (userRoutes, defaultRoute) => {
  let routes = [];
  // Redirect root routes to the home route.
  routes.push(<Redirect exact from='/' to={'/home'}  key='/'/>);
  // Home route, redirect to the user configured default if it exists.
  routes.push(<Route path='/home' render={() => {
    return _.isNil(defaultRoute) ? (<HomePage/>) : (<Redirect to={defaultRoute}/>)
  }} key='/home'/>);
  // Add any routes that the user has configured access for.
  if (!_.isNil(userRoutes) && !_.isEmpty(userRoutes.routes)) {
    userRoutes.routes.forEach((route) => {
      let component = resolveRouteToComponent(route.type);
      if (!_.isNil(component)) {
        routes.push(<Route path={route.value} render={() => { return component }} key={route.value}/>);
      }
    });
  }
  // Default route, return the not found page.
  routes.push(<Route render={() => { return (<NotFoundPage/>) }} key='default'/>);
  return routes;
};

/**
 * Maps and returns a UI component for a given route. Used to resolve the user route
 * configuration.
 *
 * For example: '/export' maps to the ExportPage component
 */
const resolveRouteToComponent = (componentType) => {
  let component = null;
  if (!_.isNil(componentType)) {
    switch(componentType) {
      case ComponentTypes.EXPORT:
        component = (<ExportPage/>);
        break;
      case ComponentTypes.USER_MANAGEMENT:
        component = (<UserManagementPage/>);
        break;
      case ComponentTypes.FLEET_MANAGEMENT:
        component = (<FleetMgmtPage/>);
        break;
      case ComponentTypes.ROUTE_ASSET_MANAGEMENT:
        component = <RouteAssetManagementPage />; 
        break;
      case ComponentTypes.EQUIPMENT_LIST:
        component = (<EquipmentListPage/>);
        break;
      case ComponentTypes.PUMP_DASHBOARD:
        component = (<PumpDashboard/>);
        break;
      case ComponentTypes.DFP_DASHBOARD:
        component = (<DfpDashboard/>);
        break;
      case ComponentTypes.CLIENT_STATUS:
        component = (<ClientStatus/>);
        break;
      case ComponentTypes.FLEET_DASHBOARD:
        component = (<FleetMap/>);
        break;
      case ComponentTypes.SYSTEM_SOFTWARE:
        component = (<SystemSoftware/>);
        break;
      case ComponentTypes.COMPONENTS:
        component = (<ComponentsDisplay/>);
        break;
      case ComponentTypes.SETTINGS:
        component = (<Settings/>);
        break;
      case ComponentTypes.COMPONENT_HOURS:
        component = (<ComponentHours/>);
        break;
      case ComponentTypes.ALARMS_DISPLAY:
        component = (<AlarmsDisplay/>);
        break;
      case ComponentTypes.FLEET_OVERVIEW:
        component = (<FleetOverview/>);
        break;
      case ComponentTypes.COIL_DASHBOARD:
        component = (<CoilDashboard/>);
        break;
      case ComponentTypes.DATA_EXPLORATION_DISPLAY_V1:
        component = (<DataExplorationDisplayV1/>);
        break;
      case ComponentTypes.DATA_EXPLORATION_DISPLAY:
        component = (<DataExplorationDisplay/>);
        break;
      case ComponentTypes.SUBSCRIPTIONS_DISPLAY:
        component = (<SubscriptionsDisplay/>);
        break;
      case ComponentTypes.JOB_OVERVIEW:
        component = (<JobOverview/>);
        break;
      case ComponentTypes.CUSTOMERS_DISPLAY:
        component = (<CustomersDisplay/>);
        break;
      case ComponentTypes.DOWNLOADS:
        component = (<DownloadsDisplay/>);
        break;
      case ComponentTypes.OWNER_MANAGEMENT:
        component = (<OwnerManagementDisplay/>);
        break;
      case ComponentTypes.JOB_HISTORY:
        component = (<JobHistoryDisplay />);
        break;
      case ComponentTypes.WELLS_DISPLAY:
        component = (<WellsDisplay/>);
        break;
      case ComponentTypes.AUDIT_LOGS:
        component = (<AuditLogsDisplay/>);
        break;
      case ComponentTypes.ASSET_TRACKING:
        component = (<AssetTrackingDisplay/>);  
        break;
      default:
        component = null;
    }
  }
  return component;
};

export {
  renderRouter
};
