import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from "../../common/factories/queryActionFactory";
import dataExplorationDisplayActionTypesV1 from './dataExplorationDisplayActionTypesV1';

import {fetchDataExplorationDisplayData, fetchDataExplorationDisplayDataAge} from "./dataExplorationDisplayQueriesV1";
import errorMessages from '../../common/errorMessages';

import moment from "moment";
import * as timeService from './services/timeService';

const selectContext = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT, 'stateDef', 'context');

const setTimeFrameDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTimeDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDurationDefaultAction = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION, 'stateDef', 'duration');

const clearDataAge = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA, 'stateDef');
const refreshRelativeTime = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE, 'stateDef');

const selectNextTruckStart = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START, 'stateDef');
const selectNextTruckEnd = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END, 'stateDef');

const selectPrevTruckStart = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START, 'stateDef');
const selectPrevTruckEnd = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END, 'stateDef');

const selectNextTruck = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectNextTruckStart(stateDef));

    await dispatch(selectNextTruckEnd(stateDef));
  }
}

const selectPrevTruck = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectPrevTruckStart(stateDef));

    await dispatch(selectPrevTruckEnd(stateDef));
  }
}

const queryData = queryActionFactory(
    dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING,
    dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS,
    dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_TRUCKS,
    fetchDataExplorationDisplayData
);

const queryDataAge = queryActionFactory(
  dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING,
  dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS,
  dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR,
  errorMessages.ERROR_RETRIEVING_DATA_AGE,
  fetchDataExplorationDisplayDataAge
);

const loadDisplay = (stateDef, startTime, endTime) => {
  return async (dispatch, getState) => {
    // Execute display query
    await dispatch(queryData(stateDef, startTime, endTime));

    await dispatch(selectContext(stateDef, getState()[stateDef.key].selectedContext));
  }
};

const setTimeFrame = (stateDef, timeFrame) => {

  return async (dispatch, getState) => {

    await dispatch(setTimeFrameDefaultAction(stateDef, timeFrame));

    if (timeFrame.label !== 'Custom') {
      let endTime = moment().startOf('minute').unix();
      let startTime = timeService.calculateStartTimeUsingTimeFrame(timeFrame);

      await dispatch(loadDisplay(stateDef, startTime, endTime));
    }
  }
}

const setCustomStartTime = (stateDef, startTime) => {

  return async(dispatch, getState) => {

    await dispatch(setCustomStartTimeDefaultAction(stateDef, startTime));

    let duration = getState()[stateDef.key].selectedCustomDuration;
    let endTime = timeService.calculateEndTimeUsingStartTimeAndDuration(startTime, duration);

    await dispatch(loadDisplay(stateDef, startTime.unix(), endTime));
  }

}

const setCustomDuration = (stateDef, duration) => {

  return async(dispatch, getState) => {

    await dispatch(setCustomDurationDefaultAction(stateDef, duration));

    let startTime = getState()[stateDef.key].selectedCustomStartTime;
    let endTime = timeService.calculateEndTimeUsingStartTimeAndDuration(startTime, duration);

    await dispatch(loadDisplay(stateDef, startTime.unix(), endTime));
  }

}

const setCustomStartTimeDisplay = defaultActionFactory(dataExplorationDisplayActionTypesV1.DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');

export {
  loadDisplay,
  queryData,
  queryDataAge,
  clearDataAge,
  refreshRelativeTime,
  selectContext,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  selectNextTruck,
  selectPrevTruck,
  setCustomStartTimeDisplay
}
