import reduceReducers from 'reduce-reducers';

import { dataExplorationDisplayState } from './dataExplorationDisplaySelector';
import truckReducer from './reducers/truckReducer';
import filterReducer from './reducers/filterReducer';
import formReducer from './reducers/formReducer';
const initialState = dataExplorationDisplayState();

const dataExplorationDisplayReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    truckReducer,
    filterReducer,
    formReducer
  );

  return reducer(state, action);

};

export default dataExplorationDisplayReducer